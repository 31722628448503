﻿import * as React from "react";

import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from "@progress/kendo-react-layout";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridSortChangeEvent,
    GridFilterChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import {
    orderBy,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
} from "@progress/kendo-data-query";

import NavigateToLogin from "../NavigateToLogin";

import { useGetExamsActiveQuery } from '../../store/sunbright-api';
import { useGetExamsQuery } from '../../store/sunbright-api';

import Exam from "./Exam";
import ExamNew from "./ExamNew";

const cellWithColor = (props: GridCellProps) => {
    const status = props.dataItem.Status;
    const color = (status === "Closed" || status === "Pending") ? "#999" : ((status === "Submitted") ? "#5cb85c" : "#5bc0de");      // gray : green : blue

    const style = {
        color: color,
        fontWeight: "900",
    };
    const field = props.field || "";
    return (
        <td style={style} data-grid-col-index={0 }>
            {props.dataItem[field]}
        </td>
    );
};

const initialSortActive: Array<SortDescriptor> = [
    { field: "id", dir: "desc" },
];
const initialSortClosed: Array<SortDescriptor> = [
    { field: "id", dir: "desc" },
];

const initialFilterActive: CompositeFilterDescriptor = {
    logic: "and",
    filters: []
};
const initialFilterClosed: CompositeFilterDescriptor = {
    logic: "and",
    filters: []
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ExamsGrid = (props: any) => {
    const { data:dataActive, error:errorActive, isLoading:isLoadingActive } = useGetExamsActiveQuery("test");
    const { data, error, isLoading } = useGetExamsQuery("test");

    const [gridHeightActive, setGridHeightActive] = React.useState<string>("600px");
    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeightActive((window.innerHeight - 140) + "px");
    }, [dataActive]);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 140) + "px");
    }, [data]);

    const handleResize = () => {
        setGridHeightActive((window.innerHeight - 140) + "px");
        setGridHeight((window.innerHeight - 140) + "px");
    };

    const [sortActive, setSortActive] = React.useState(initialSortActive);
    const [sortClosed, setSortClosed] = React.useState(initialSortClosed);
    const [filterActive, setFilterActive] = React.useState(initialFilterActive);
    const [filterClosed, setFilterClosed] = React.useState(initialFilterClosed);

    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    const handleSelectTab = (e: TabStripSelectEventArguments) => {
        setSelectedTab(e.selected);
    };

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const [newExam, setNewExam] = React.useState<boolean>(false);

    const [selectedStateActive, setSelectedStateActive] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const handleSelectionChangeActive = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateActive,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateActive(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };

    const [selectedStateClosed, setSelectedStateClosed] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const handleSelectionChangeClosed = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateClosed,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateClosed(newState);

        setSelectedUID(Object.keys(newState)[0]);
   };

    return (
        <React.Fragment>
            {(selectedUID.length !== 0) ?
                (< Exam
                    examID={selectedUID}
                    returnLabel="Return to Exams"
                    viewOnly={false}
                    onReturn={() => setSelectedUID("")}
                />
                ) :
                (newExam ?
                    (<ExamNew
                        onReturn={() => setNewExam(false)}
                    />) :
                    (<div>
                    <Button
                        themeColor={"primary"}
                        icon="plus-circle"
                        onClick={() => setNewExam(true)}
                        style={{ position: "absolute", top: "46px", right: "64px", width: "128px", zIndex:"99" }}>
                    New Exam
                </Button>
                <TabStrip selected={selectedTab} onSelect={handleSelectTab} style={{ paddingTop: "4px" }}>
                    <TabStripTab title="Exams Requiring Further Action">
                                {errorActive ? (<NavigateToLogin />) : isLoadingActive ? <Loader size="large" type="converging-spinner" /> : dataActive ? (
                            <Grid
                                style={{ height: gridHeightActive }}
                                className="examGrid sds-selectable"
                                data={orderBy(filterBy(dataActive.map((item:any) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedStateActive[idGetter(item)]
                                })), filterActive), sortActive)}
                                sortable={true}
                                sort={sortActive}
                                onSortChange={(e: GridSortChangeEvent) => setSortActive(e.sort)}
                                filterable={true}
                                filter={filterActive}
                                onFilterChange={(e: GridFilterChangeEvent) => setFilterActive(e.filter)}
                                resizable={true}
                                reorderable={true}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    mode: "single",
                                }}
                                onSelectionChange={handleSelectionChangeActive}
                                size={"small"}
                            >
                                <GridColumn field="status" title="Status" filterable={false} cell={cellWithColor} />
                                <GridColumn field="services" title="Services" filterable={true} filter="text" />
                                <GridColumn field="petOwner" title="Pet (Owner)" filterable={true} filter="text" />
                                <GridColumn field="species" title="Species" filterable={false} />
                                <GridColumn field="agencyGroup" title="Agency Group" filterable={false} />
                                <GridColumn field="caseWorker" title="Case Worker" filterable={false} />
                                <GridColumn field="submitted" title="Submitted" filterable={false} format="{0:MMM d yyyy}" />
                                <GridColumn field="appStart" title="Scheduled" filterable={false} format="{0:MMM d yyyy}" />
                                <GridColumn field="updated" title="Updated" filterable={false} format="{0:MMM d yyyy}" />
                                <GridColumn field="id" title="ID" filterable={false} />
                            </Grid>) : null}
                    </TabStripTab>
                    <TabStripTab title="Closed Exams">
                                {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? (
                            <Grid
                                style={{ height: gridHeight }}
                                className="examGrid sds-selectable"
                                data={orderBy(filterBy(data.map((item: any) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedStateClosed[idGetter(item)]
                                })), filterClosed), sortClosed)}
                                sortable={true}
                                sort={sortClosed}
                                onSortChange={(e: GridSortChangeEvent) => setSortClosed(e.sort)}
                                filterable={true}
                                filter={filterClosed}
                                onFilterChange={(e: GridFilterChangeEvent) => setFilterClosed(e.filter)}
                                resizable={true}
                                reorderable={true}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    mode: "single",
                                }}
                                onSelectionChange={handleSelectionChangeClosed}
                                size={"small"}
                            >
                                <GridColumn field="status" title="Status" filterable={false} cell={cellWithColor} />
                                <GridColumn field="services" title="Services" filterable={true} filter="text" />
                                <GridColumn field="petOwner" title="Pet (Owner)" filterable={true} filter="text" />
                                <GridColumn field="species" title="Species" filterable={false} />
                                <GridColumn field="agencyGroup" title="Agency Group" filterable={false} />
                                <GridColumn field="caseWorker" title="Case Worker" filterable={true} />
                                <GridColumn field="opened" title="Opened" filterable={false} format="{0:MMM d yyyy}" />
                                <GridColumn field="appStart" title="Scheduled" filterable={false} format="{0:MMM d yyyy}" />
                                <GridColumn field="veterinarian" title="Veterinarian" filterable={true} />
                                    <GridColumn field="id" title="ID" filterable={true} filter="text" />
                            </Grid>) : null}
                    </TabStripTab>
                        </TabStrip>
                </div>)
            )}
        </React.Fragment>
    );
}
export default ExamsGrid;
