import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import AuthGuard from "./components/AuthGuard";
import Home from "./components/Home";
import Login from "./components/Login";

function App() {
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<AuthGuard element={<Home />} />} />
                  <Route path="*" element={<AuthGuard element={<Navigate to="/" />} />} />
              </Routes>
          </BrowserRouter>
     </div>
  );
}

export default App;
