import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { DropDownListChangeEvent, DropDownListFilterChangeEvent} from "@progress/kendo-react-dropdowns";
import { StackLayout } from "@progress/kendo-react-layout";
import {
    CompositeFilterDescriptor,
    filterBy,
    FilterDescriptor,
} from "@progress/kendo-data-query";

import { FormInput, FormDropDownList, FormDatePicker } from "../FormComponents";
import { requiredValidator, emailValidator, phoneValidator } from "../validators";

import { useGetAgencyNamesQuery } from '../../store/sunbright-api';
import { useLazyGetCwNamesQuery } from '../../store/sunbright-api';
import { useLazyGetClientNamesQuery } from '../../store/sunbright-api';
import { useLazyGetPersonQuery } from '../../store/sunbright-api';
import { useLazyGetPetNamesQuery } from '../../store/sunbright-api';
import { useLazyGetPetQuery } from '../../store/sunbright-api';

import { useAppSelector } from "../../store/store";

const allSpecies = [
    "Dog",
    "Cat",
    "Equine",
    "Bovine",
    "Caprine",
    "Ovine",
    "Porcine",
    "Avian",
    "Ferret",
    "Rabbit",
    "Small Rodent",
    "Amphibian",
    "Reptile",
    "Fish",
    "Exotic",
    "Small Mammal/Other"
];

const dogBreeds = [
    "Mixed breed - weight < 10 kg",
    "Mixed breed - weight > 20 kg",
    "Mixed breed - weight 10-20 kg",
    "Airedale Terrier",
    "Alaskan Malamute",
    "American Akita",
    "American Bulldog",
    "American Cocker Spaniel",
    "American Eskimo Dog",
    "American Hairless Terrier",
    "American Mastiff",
    "American Pit Bull Terrier",
    "American Staffordshire",
    "Australian Cattle Dog",
    "Australian Shepherd",
    "Australian Silky Terrier",
    "Basenji",
    "Basset Hound",
    "Beagle",
    "Bearded Collie",
    "Bernese Mountain Dog",
    "Bichon Frise",
    "Bloodhound",
    "Border Collie",
    "Boston Terrier",
    "Bouvier de Flandres",
    "Boxer",
    "Brittany",
    "Bull Terrier",
    "Bull Terrier (Miniature)",
    "Bulldog",
    "Bullmastiff",
    "Cairn Terrier",
    "Cardigan Welsh Corgi",
    "Cavalier King Charles Spaniel",
    "Chihuahua",
    "Chinese Crested Dog",
    "Chow Chow",
    "Corgi",
    "Dachshund",
    "Dalmation",
    "Doberman Pinscher",
    "Dogue de Bordeaux",
    "English Cocker Spaniel",
    "English Mastiff",
    "English Setter",
    "English Springer Spaniel",
    "English Toy Terrier (Black & Tan)",
    "Finnish Spitz",
    "Fox Terrier (Smooth)",
    "French Brittany",
    "French Bulldog",
    "German Pinscher",
    "German Shepherd Dog",
    "Giant Schnauzer",
    "Golden Retriever",
    "Gordon Setter",
    "Great Dane",
    "Great Pyrenees",
    "Greater Swiss Mountain Dog",
    "Greyhound",
    "Griffon Bruxellois",
    "Havanese",
    "Irish Setter",
    "Irish Staffordshire Bull Terrier",
    "Irish Wolfhound",
    "Jack Russell Terrier",
    "King Charles Spaniel",
    "King Shepherd",
    "Labrador Retriever",
    "Leonberger",
    "Lhasa Apso",
    "Maltese",
    "Manchester Terrier",
    "Minature Schnauzer",
    "Newfoundland",
    "Nova Scotia Duck-Tolling",
    "Old English Sheepdog",
    "Papillon",
    "Pembroke Welsh Corgi",
    "Pointer",
    "Pomeranian",
    "Poodle",
    "Pug",
    "Rat Terrier",
    "Retriever",
    "Rhodesian Ridgeback",
    "Rottweiler",
    "Rough Collie",
    "Schipperke",
    "Scottish Terrier",
    "Shar Pei",
    "Shetland Sheepdog",
    "Shiba Inu",
    "Shih Tzu",
    "Siberian Husky",
    "Smooth Collie",
    "Soft-Coated Wheaton Terrier",
    "St. Bernard",
    "Staffordshire Bull Terrier",
    "Standard Schnauzer",
    "Terrier",
    "Weimaraner",
    "West Highland White Terrier",
    "Whippet",
    "Yorkshire Terrier"
];

const catBreeds = [
    "Mixed breed - domestic long hair",
    "Mixed breed - domestic medium hair",
    "Mixed breed - domestic shorthair",
    "British Shorthair",
    "Burmese",
    "Calico",
    "Domestic Long Hair",
    "Domestic Medium Hair",
    "Hairless",
    "himAlayan",
    "Maine Coone",
    "Persian",
    "Rex",
    "Scottish Fold",
    "Siamese",
    "Tortoiseshell",
];

const otherBreeds = [
    "N/A"
];

const allGenders = ["Female", "Male", "Neutered, Male", "Spayed, Female", "Unknown"];

export interface ExamNewClientPetProps {
    newExam: boolean,
    form: any,
}
const ExamNewClientPet = ({
    newExam,
    form,
}: ExamNewClientPetProps) => {

    const auth = useAppSelector((state: any) => state.auth);
    const personID = (auth && (auth.user !== undefined) && (auth.user.id !== undefined)) ? auth.user.id : -1;
    const role = (auth && (auth.user !== undefined) && (auth.user.role !== undefined)) ? auth.user.role : "";

    const { data: agencynames, error: agencynamesError, isLoading: agencynamesLoading } = useGetAgencyNamesQuery(personID);

    const [getCwNames, { data: cwnames, error: cwnamesError, isLoading: cwnamesLoading }] = useLazyGetCwNamesQuery();
    const [getClientNames, { data: clientnames, error: clientnamesError, isLoading: cliennamestLoading }] = useLazyGetClientNamesQuery();
    const [getClient, { data: client, error: clientError, isLoading: clientLoading }] = useLazyGetPersonQuery();
    const [getPetNames, { data: petnames, error: petnamesError, isLoading: petnamesLoading }] = useLazyGetPetNamesQuery();
    const [getPet, { data: pet, error: petError, isLoading: petLoading }] = useLazyGetPetQuery();

    const [clientnamesFiltered, setClientNamesFiltered] = React.useState<any[]>([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks

    const [singleAgency, setSingleAgency] = React.useState<boolean>(false);
    const [singleCaseWorker, setSingleCaseWorker] = React.useState<boolean>(false);

    React.useEffect(() => {
        const single: boolean = Array.isArray(agencynames) && (agencynames.length === 1);
        setSingleAgency(single);

        if (newExam && single) {
            form.current.onChange("agencynamecombo", agencynames[0]);
            form.current.values.agencynamecombo = agencynames[0];
            getCwNames(agencynames[0].uid);
        }
    }, [agencynames]);

    React.useEffect(() => {
        const single: boolean = Array.isArray(cwnames) && (cwnames.length === 1);
        setSingleCaseWorker(single);

        if (newExam && single) {
            form.current.onChange("cwnamecombo", cwnames[0]);
            form.current.values.cwnamecombo = cwnames[0];
            getClientNames(cwnames[0].uid);
        }
    }, [cwnames]);

    React.useEffect(() => {
        setClientNamesFiltered(clientnames);
    }, [clientnames]);

    React.useEffect(() => {
        if (client) {
            form.current.onChange("ownerfirst", { value: client.firstName });
            form.current.onChange("ownerlast", { value: client.lastName });
            form.current.onChange("ownermobile", { value: client.mobilePhone });
            form.current.onChange("owneremail", { value: client.email });
            form.current.onChange("owneraddress1", { value: client.address1 });
            form.current.onChange("ownercity", { value: client.city });
            form.current.onChange("ownerprovince", { value: client.province });
            form.current.onChange("ownerpostalcode", { value: client.postalCode });

            const multiFields: number[] = [170, 171, 175, 182, 183,
                199, 202, 206, 207, 209, 215, 218, 219, 231, 233, 236, 237, 324, 382];
            const dateFields: number[] = [149];

            client.eligibility.fields.forEach((field: any) => {
                var answerKey: string = "";

                if (field.t === "finding") {
                    answerKey = "A" + field.result.findingID;

                    if (multiFields.includes(field.result.findingID)) {
                        form.current.values[answerKey] = field.result.a.split(" | ");
                    }
                    else {
                        form.current.values[answerKey] = field.result.a;
                    }
                    form.current.values["N" + field.result.findingID] = field.result.n;
                }
            })
            client.waivers.fields.forEach((field: any) => {
                var answerKey: string = "";

                if (field.t === "finding") {
                    answerKey = "A" + field.result.findingID;

                    if (multiFields.includes(field.result.findingID)) {
                        form.current.values[answerKey] = field.result.a.split(" | ");
                    }
                    else if (dateFields.includes(field.result.findingID)) {
                        form.current.values[answerKey] = (field.result.a.length > 0) ? new Date(field.result.a) : "";
                    }
                    else {
                        form.current.values[answerKey] = field.result.a;
                    }
                    form.current.values["N" + field.result.findingID] = field.result.n;
                }
            })
            client.questionnaire.fields.forEach((field: any) => {
                var answerKey: string = "";

                if (field.t === "finding") {
                    answerKey = "A" + field.result.findingID;

                    if (multiFields.includes(field.result.findingID)) {
                        form.current.values[answerKey] = field.result.a.split(" | ");
                    }
                    else {
                        form.current.values[answerKey] = field.result.a;
                    }
                    form.current.values["N" + field.result.findingID] = field.result.n;
                }
            })
            setPetValue(null);
            getPet("-1");
        }
    }, [client]);

    React.useEffect(() => {
        form.current.onChange("petnamecombo", { value: null });
    }, [petnames]);

    React.useEffect(() => {
        if (pet) {
            form.current.onChange("petname", { value: pet.petName });
            form.current.onChange("petspecies", { value: pet.species });
            form.current.onChange("petbreed", { value: pet.breed });
            form.current.onChange("petbreedother", { value: pet.breedOther });
            form.current.onChange("petcolor", { value: pet.color });
            form.current.onChange("petdob", { value: (pet.dob && (pet.dob.length > 0)) ? new Date(pet.dob) : null });
            form.current.onChange("petgender", { value: pet.gender });
            const weight: string[] = pet.weight.split(' ');
            form.current.onChange("petweight", { value: weight[0] });
            form.current.onChange("petmicrochiptag", { value: pet.microchipTag });
            form.current.onChange("petmicrochipref", { value: pet.microchipRef });
            form.current.onChange("petalert", { value: pet.alert });
        }
    }, [pet]);

    const [clientValue, setClientValue] = React.useState<any>(null);
    const [petValue, setPetValue] = React.useState<any>(null);

    const handleAgencyChange = (e: DropDownListChangeEvent) => {
        getCwNames(e.value.uid);
    }

    const handleCwChange = (e: DropDownListChangeEvent) => {
        getClientNames(e.value.uid);
    }

    const handleClientChange = (e: DropDownListChangeEvent) => {
        setClientValue(e.value);

        getClient(e.value.uid);
        getPetNames(e.value.uid);
    }

    const filterClientNames = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
        const data = clientnames.slice();
        return filterBy(data, filter);
    };

    const handleClientFilterChange = (e: DropDownListFilterChangeEvent) => {
        setClientNamesFiltered(filterClientNames(e.filter));
    }

    const handlePetChange = (e: DropDownListChangeEvent) => {
        setPetValue(e.value);

        getPet(e.value.uid);
    }

    const [breedList, setBreedList] = React.useState<string[]>(otherBreeds);

    const handleSpeciesChange = (e: DropDownListChangeEvent) => {
        if (e.value === "Dog") {
            setBreedList(dogBreeds);
        } else if (e.value === "Cat") {
            setBreedList(catBreeds);
        } else {
            setBreedList(otherBreeds);
        }
        form.current.onChange("breed", { value: null });
    }

    return (
        (agencynames ?
            (
        <div className={"sds-questionnaire"}>
        <fieldset
            className={"k-form-fieldset"}
                    >
           { !newExam && (
           <div>
                <legend
                    style={{ marginBottom:"-16px" }}
                    className={"k-form-legend"}>
                    Case Worker:
                </legend>
                <StackLayout gap={16}>
                    <Field
                        label="Agency"
                        name={"cwagency"}
                        component={FormInput}
                        disabled={true}
                    />
                    <Field
                        label="Name"
                        name={"cwname"}
                        component={FormInput}
                        disabled={true}
                    />
                    <Field
                        label="Email"
                        name={"cwemail"}
                        component={FormInput}
                        disabled={true}
                    />
                </StackLayout>
            </div>
            )}

           <div>
                <legend className={"k-form-legend"} style={{ marginTop:"16px", marginBottom:"-16px" }}>Owner: </legend>
           </div>
            { newExam && (
                <StackLayout gap={16}>
                    <Field
                        label={"Agency*"}
                        name={"agencynamecombo"}
                        component={FormDropDownList}
                        data={agencynames}
                        defaultValue={singleAgency ? agencynames[0] : null}
                        textField={"name"}
                        onChange={handleAgencyChange}
                        validator={singleAgency ? undefined : requiredValidator}
                    />
                    <Field
                        label={"Case Worker*"}
                        name={"cwnamecombo"}
                        component={FormDropDownList}
                        data={cwnames}
                        defaultValue={singleCaseWorker ? cwnames[0] : null}
                        textField={"name"}
                        onChange={handleCwChange}
                        validator={singleCaseWorker ? undefined : requiredValidator}
                    />
                    <Field
                        label={"Client*"}
                        name={"clientnamecombo"}
                        component={FormDropDownList}
                                    data={clientnamesFiltered ? [{ uid: -1, name: "*New*" }].concat(clientnamesFiltered) : []}
                                    textField={"name"}
                                    filterable={clientnames && Array.isArray(clientnames) && (clientnames.length > 10)}
                                    onFilterChange={handleClientFilterChange}
                        onChange={handleClientChange}
                        validator={requiredValidator}
                    />
                    <div />
                </StackLayout>
                )}

            <StackLayout gap={16}>
                <Field
                    label={"First Name*"}
                    name={"ownerfirst"}
                    component={FormInput}
                    disabled={newExam && !clientValue}
                    validator={requiredValidator}
                />
                <Field
                    label={"Last Name*"}
                    name={"ownerlast"}
                    component={FormInput}
                    disabled={newExam && !clientValue}
                    validator={requiredValidator}
                />
                <Field
                    label={"Phone"}
                    name={"ownermobile"}
                    component={FormInput}
                    disabled={newExam && !clientValue}
                    validator={phoneValidator}
                />
                <Field
                    label={"Email"}
                    name={"owneremail"}
                    component={FormInput}
                    disabled={newExam && !clientValue}
                    validator={emailValidator}
                />
            </StackLayout>
            <StackLayout gap={16}>
                <Field
                    label="Address"
                    name={"owneraddress1"}
                    disabled={newExam && !clientValue}
                    component={FormInput}
               />
                <Field
                    label="City"
                    name={"ownercity"}
                    disabled={newExam && !clientValue}
                    component={FormInput}
               />
                <Field
                    label="Province"
                    name={"ownerprovince"}
                    disabled={newExam && !clientValue}
                   component={FormInput}
                />
                <Field
                    label="Postal Code"
                    name={"ownerpostalcode"}
                    disabled={newExam && !clientValue}
                   className={"sds-uppercase" }
                    component={FormInput}
                />
            </StackLayout>
            <div>
                <legend
                    style={{ marginTop: "32px", marginBottom:"-16px" }}
                    className={"k-form-legend"}>
                    Pet:
                </legend>
            </div>
            {newExam && (
            <div>
               <StackLayout gap={16}>
               <Field
                    name={"petnamecombo"}
                    data={[{ uid: -1, name: "*New*" }].concat(petnames ? petnames : [])}
                    component={FormDropDownList}
                    textField={"name"}
                    onChange={handlePetChange}
                    validator={requiredValidator}
                />
                <div /><div /><div />
                </StackLayout>
            </div>
            )}
            <StackLayout gap={16}>
                <Field
                    label="Name*"
                    name={"petname"}
                    component={FormInput}
                    disabled={newExam && !petValue}
                    validator={requiredValidator}
                />
                <Field
                    label="Species*"
                    name={"petspecies"}
                    component={FormDropDownList}
                    disabled={newExam && !petValue}
                    data={allSpecies}
                    onChange={handleSpeciesChange}
                    validator={requiredValidator}
                />
                <Field
                    label="Breed*"
                    name={"petbreed"}
                    disabled={newExam && !petValue}
                    component={FormDropDownList}
                    data={breedList}
                    validator={requiredValidator}
                />
                <Field
                    label="Breed (Other)"
                    name={"petbreedother"}
                    component={FormInput}
                    disabled={newExam && !petValue}
                />
                <Field
                    label="Color*"
                    name={"petcolor"}
                    component={FormInput}
                    disabled={newExam && !petValue}
                    validator={requiredValidator}
                />
            </StackLayout>
            <StackLayout gap={16}>
                <Field
                    label={"Birthdate"}
                    name={"petdob"}
                    component={FormDatePicker}
                    placeholder=""
                    disabled={false}
                    validator={newExam ? undefined : requiredValidator}
                />
                <Field
                    label="Gender*"
                    name={"petgender"}
                    component={FormDropDownList}
                    disabled={newExam && !petValue}
                    data={allGenders}
                    validator={requiredValidator}
                />
                <Field
                    label="Weight (kg)*"
                    name={"petweight"}
                    disabled={newExam && !petValue}
                    component={FormInput}
                    validator={requiredValidator}
                />
                <Field
                    label="Microchip Tag"
                    name={"petmicrochiptag"}
                    disabled={newExam && !petValue}
                    component={FormInput}
                />
                <Field
                    label="Microchip #"
                    name={"petmicrochipref"}
                    disabled={newExam && !petValue}
                    component={FormInput}
                />
            </StackLayout>

            {!newExam && (
                <StackLayout gap={16}>
                    <Field
                        label={"Note"}
                        name={"petalert"}
                        component={FormInput}
                        placeholder=""
                        disabled={false}
                        style={{ color: "red" }}
                    />
                </StackLayout>
            )}
        </fieldset>
        </div>) : null)
      );
}
export default ExamNewClientPet;