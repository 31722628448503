﻿import * as React from "react";

import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { InputChangeEvent } from "@progress/kendo-react-inputs";

import { FormDropDownList, FormInput, FormTextArea, FormMultiSelect, FormDatePicker, FormCheckbox } from "../FormComponents";
import { requiredValidator } from "../validators";

export interface ExamNewRecordProps {
    species: string;
    formRenderProps: FormRenderProps;
}

const ExamNewRecord = ({
    species,
    formRenderProps
}: ExamNewRecordProps) => {

    const [newMicrochip, setNewMicrochip] = React.useState<boolean>(false);
    const [partialExam, setPartialExam] = React.useState<boolean>(formRenderProps.valueGetter("A379") === "Yes");
    const [reqdSuffix, setReqdSuffix] = React.useState<string>((formRenderProps.valueGetter("A379") === "Yes") ? "" : "*");

    const onResponseChange = (event: DropDownListChangeEvent, name:string) => {
        if (event.target.index > 0) {
            const currentValue = formRenderProps.valueGetter(name);
            formRenderProps.onChange(name, {
                value: ((currentValue ? (currentValue + "\r") : "") + event.target.value)
            });
        }
    }

    const onResponseDrugChange = (event: DropDownListChangeEvent, name: string) => {
        if (event.target.index > 0) {
            const currentValue = formRenderProps.valueGetter(name);
            formRenderProps.onChange(name, {
                value: ((currentValue ? (currentValue + "\r") : "") + event.target.value.value)
            });
        }
    }

    const onInputChange = (event: InputChangeEvent, name: string) => {
        if (name === "A339") {
            setNewMicrochip(event.value.length > 0);
        }
    }

    const onPartialExamChange = (e: DropDownListChangeEvent) => {
        setPartialExam(e.value === "Yes");
        setReqdSuffix((e.value === "Yes") ? "" : "*");
    }

    return (
        <React.Fragment>
        <div>
            <div className={"sds-questionnaire"}>
               <fieldset
                    style={{}}
                    className={"k-form-fieldset"}
                >
                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Exam Record
                    </legend>

                    <StackLayout gap={48}>
                        <Field
                            label={"Is this a partial exam?"}
                            name={"A379"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                            onChange={onPartialExamChange}
                        />
                        <Field
                            label={"Notes"}
                            name={"N379"}
                            component={FormInput}
                        />
                    </StackLayout>

                        <Field
                        label={"Breed" + reqdSuffix}
                        name={"A195"}
                        component={FormInput}
                        validator={partialExam ? [] : requiredValidator}
                    />

                    <StackLayout gap={48}>
                        <Field
                                label={"Mixed or Purebred" + reqdSuffix}
                            name={"A196"}
                            component={FormDropDownList}
                            data={["Mixed", "Purebred"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N196"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Pet History
                    </legend>

                    <StackLayout gap={48}>
                        <Field
                                label={"Where did you get this pet?" + reqdSuffix}
                            name={"A199"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Family member/friend", "Stray", "Litter from my own pet or a friend/family's pet",
                                "Advertisement (Kijiji, newspaper)", "Humane Society/Shelter/Animal Services",
                                "Pet store", "Breeder", "Other"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N199"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"When did you get your pet?" + reqdSuffix}
                            name={"A200"}
                            component={FormDatePicker}
                            validator={partialExam ? [] : requiredValidator}
                            placeholder=""
                        />
                        <Field
                            label={"Notes"}
                            name={"N200"}
                            component={FormInput}
                        />
                        <div />
                    </StackLayout>

                    <Field
                        label={"How many other animals do you currently have in your household?"}
                        name={"A201"}
                        component={FormInput}
                    />

                    <StackLayout gap={48}>
                        <Field
                            label={"How did you hear of this clinic?"}
                            name={"A202"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Case or social worker", "Housing worker", "Street outreach", "Drop in staff", "Health professional",
                                "Friend", "Family member", "Other", "Flyer/poster", "I’ve been to a CVO clinic before"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N202"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <Field
                            label={"Presenting Medical Concern" + reqdSuffix}
                        name={"A203"}
                        style={{ height: "110px" }}
                        component={FormTextArea}
                        validator={partialExam ? [] : requiredValidator}
                    />
                    <DropDownList
                        className={"sds-normal" }
                        defaultValue={"Responses..."}
                        data={["Responses...", "Booster vaccine visit", "Recheck for medical problem", "Vaccination and wellness exam"]}
                        onChange={(e: DropDownListChangeEvent) => onResponseChange(e, "A203")}
                    />

                    <StackLayout gap={48}>
                        <Field
                                label={"Has this pet had any previous health problems?" + reqdSuffix}
                            name={"A204"}
                            component={FormDropDownList}
                            data={["Yes", "No", "I dont know"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N204"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <Field
                        label={"If yes, describe."}
                        name={"A205"}
                        style={{ height: "110px" }}
                        component={FormTextArea}
                    />

                    <StackLayout gap={48}>
                        <Field
                            label={"What activities do you enjoy with your pet?"}
                            name={"A206"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Walking outdoors", "Playing/games indoors", "Playing/games outdoors",
                                "Obedience or trick training", "Other (Please describe)"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N206"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Current food"}
                            name={"A207"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Dry food", "Wet food", "Dry and wet food",
                                "Home cooked", "Raw", "Human food"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N207"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <Field
                            label={"Animal health history" + reqdSuffix}
                        name={"A208"}
                        style={{ height: "110px" }}
                        component={FormTextArea}
                        validator={partialExam ? [] : requiredValidator}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={"Responses..."}
                        data={["Responses...", "No health concerns identified by client"]}
                        onChange={(e: DropDownListChangeEvent) => onResponseChange(e, "A208")}
                    />

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Any change in the following:
                    </legend>

                    <StackLayout gap={48}>
                        <Field
                                label={"Attitude" + reqdSuffix}
                            name={"A209"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["BAR (bright, alert, responsive)", "QAR (quiet, alert, responsive)", "Lethargic",
                                "Anxious/fearful", "Other (Text Box)"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N209"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Appetite" + reqdSuffix}
                            name={"A210"}
                            component={FormDropDownList}
                            data={["Normal", "Reduced", "Increased"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N210"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Drinking" + reqdSuffix}
                            name={"A211"}
                            component={FormDropDownList}
                            data={["Normal", "Reduced", "Increased"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N211"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Bowels" + reqdSuffix}
                            name={"A212"}
                            component={FormDropDownList}
                            data={["Normal", "Reduced", "Increased"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N212"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Urination Amount" + reqdSuffix}
                            name={"A213"}
                            component={FormDropDownList}
                            data={["Normal", "Reduced", "Increased"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N213"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Urination Frequency" + reqdSuffix}
                            name={"A214"}
                            component={FormDropDownList}
                            data={["Normal", "Reduced", "Increased"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N214"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Elimination Habits/behaviors (e.g. litter box)" + reqdSuffix}
                            name={"A215"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Normal", "Peeing outside the box or in the house", "Pooping outside the box or in the house",
                                "Spraying"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N215"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Have you noted any of the following?
                    </legend>

                    <StackLayout gap={48}>
                        <Field
                                label={"Coughing" + reqdSuffix}
                            name={"A216"}
                            component={FormDropDownList}
                            data={["Yes", "No", "Occasional"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N216"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Sneezing" + reqdSuffix}
                            name={"A217"}
                            component={FormDropDownList}
                            data={["Yes", "No", "Occasional"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N217"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Vomiting" + reqdSuffix}
                            name={"A218"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["None", "Yes-chronic", "Yes- acute (< 2 weeks)", "Gulping, licking, excessive gas, eating foreign objects",
                                "Regurgitation (spitting up with no heaving)", "Other (please describe)"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N218"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Diarrhea/ GI" + reqdSuffix}
                            name={"A219"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["None", "Yes - chronic", "Yes- acute (< 2 weeks)", "Soft or liquid feces, with normal frequency of BM",
                                "Straining, red blood, frequency or mucous seen",
                                "Parasites seen", "Constipation", "Other (Please describe)"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N219"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Purina fecal score" + reqdSuffix}
                            name={"A220"}
                            component={FormDropDownList}
                            data={["1", "2", "3", "4", "5", "6", "7"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N220"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"2nd Hand Smoke Exposure" + reqdSuffix}
                            name={"A221"}
                            component={FormDropDownList}
                            data={["Yes", "No", "Occasional"]}
                            validator={partialExam ? [] : requiredValidator}
                       />
                        <Field
                            label={"Notes"}
                            name={"N221"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <Field
                        label={"Last Heat"}
                        name={"A222"}
                        component={FormInput}
                    />

                    <StackLayout gap={48}>
                        <Field
                                label={"History of biting a person in the last 10 days?" + reqdSuffix}
                            name={"A223"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N223"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Physical Exam Findings
                    </legend>

                    <StackLayout gap={48}>
                        <Field
                                label={"Weight (kg)" + reqdSuffix}
                            name={"A224"}
                            component={FormInput}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N224"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"BCS (1-9)" + reqdSuffix}
                            name={"A225"}
                            component={FormInput}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N225"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Temperature (degrees C)"}
                            name={"A226"}
                            component={FormInput}
                        />
                        <Field
                            label={"Notes"}
                            name={"N226"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"HR (BPM)" + reqdSuffix}
                            name={"A227"}
                            component={FormInput}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N227"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"RR (breaths/min)" + reqdSuffix}
                            name={"A228"}
                            component={FormInput}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N228"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <Field
                            label={"CRT" + reqdSuffix}
                        name={"A229"}
                        component={FormDropDownList}
                        data={["Less than 2", "Prolonged"]}
                        validator={partialExam ? [] : requiredValidator}
                    />

                    <StackLayout gap={48}>
                        <Field
                                label={"Eyes" + reqdSuffix}
                            name={"A230"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not Examined"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N230"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Ears" + reqdSuffix}
                            name={"A231"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Normal", "Otitis externa", "Not examined", "Erythema",
                                "Swelling", "Discharge", "Aural hematoma(s)", "Other (Please describe)"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N231"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Ear Mites"}
                            name={"A232"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N232"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Skin"}
                            name={"A233"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Not examined", "Normal", "Callus (thickened skin overlying pressure points)",
                                "Collarettes (circular, peeling skin lesions)", "Crusts (dried exudate containing blood/serum/scales/pus)",
                                "Depigmentation (loss of pigmentation)", "Discharge and/or exudate", "Erosion and/or ulcer",
                                "Erythema (reddening of the skin, often in patches)", "Excoriation (erosion/ulcer caused by self-trauma, usually linear)",
                                "Feline Miliary dermatitis (very small, crusted elevations <1 cm)", "Fissure (deep split through the skin)",
                                "Hyperkeratosis (thickening of nasal planum/paw pads)", "Hyperpigmentation (increased pigmentation)",
                                "Lichenification (thickening/hardening of skin)", "Nodule (solid elevation >1 cm in diameter)",
                                "Papules (solid elevated lesions < 1 cm)", "Plaque (flat elevation of skin >1cm in diameter)",
                                "Pruritus (patient noticeably pruritic during consultation)", "Pustules", "Scaling (flakes of cornified cells)",
                                "Scar", "Subcutaneous or subcutaneous mass", "Swelling",
                                "Vesicles and/or bullae (sharply demarcated elevation of skin containing clear fluid)",
                                "Wheal (hive)", "Other: Specify"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N233"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Skin - itch scale" + reqdSuffix}
                            name={"A234"}
                            component={FormDropDownList}
                            data={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N234"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Deciduous (baby) Teeth" + reqdSuffix}
                            name={"A235"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N235"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Dental Health"}
                            name={"A236"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Not examined", "Normal", "History of dental pain (dropping food, avoiding dry)",
                                "Missing teeth", "Fractured teeth", "Halitosis", "Mild calculus", "Significant calculus – multiple teeth",
                                "Gingival recession and root exposure", "Obvious periodontal disease (pockets, bleeding/ pus, loose teeth)",
                                "Feline resorptive lesions", "Other (please describe)"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N236"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Oral cavity - non-dental structures" + reqdSuffix}
                            name={"A237"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Normal", "Not examined", "Masses / nodules", "Ulceration/ wounds",
                                "Tongue abnormalities", "Other (please describe)"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N237"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Heart" + reqdSuffix}
                            name={"A238"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined", "Heart Murmur"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N238"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Respiratory" + reqdSuffix}
                            name={"A239"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined", "Panting"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N239"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Abdomen/Palpation" + reqdSuffix}
                            name={"A240"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N240"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Hernia"}
                            name={"A241"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N241"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Urogenital" + reqdSuffix}
                            name={"A242"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N242"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Musculoskeletal" + reqdSuffix}
                            name={"A243"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N243"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Neurological"}
                            name={"A244"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N244"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                                label={"Lymphatic" + reqdSuffix}
                            name={"A245"}
                            component={FormDropDownList}
                            data={["Normal", "Abnormal", "Not examined"]}
                            validator={partialExam ? [] : requiredValidator}
                        />
                        <Field
                            label={"Notes"}
                            name={"N245"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Assessment, Diagnostics, Treatment
                    </legend>

                    <Field
                            label={"Assessment/Dx" + reqdSuffix}
                        name={"A246"}
                        style={{ height: "110px" }}
                        component={FormTextArea}
                        validator={partialExam ? [] : requiredValidator}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={"Responses..."}
                        data={["Responses...", "Healthy, OK to vaccinate"]}
                        onChange={(e: DropDownListChangeEvent) => onResponseChange(e, "A246")}
                    />

                    <Field
                        label={"Problem list, differential diagnoses and tentative diagnoses (if any)"}
                        name={"A247"}
                        style={{ height: "110px" }}
                        component={FormTextArea}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={"Responses..."}
                        data={["Responses...", "M1 gingivitis/tartar esp teeth", "M2 gingivitis/tarter esp teeth", "M3 gingivitis/tarter esp teeth",
                            "M1 Obesity", "M2 Obesity", "M3 Obesity",
                            "M1 Otitis externa (AU/AD/AS)", "M2 Otitis externa (AU/AD/AS)", "M3 Otitis externa (AU/AD/AS)",
                            "Skin mass"]}
                        onChange={(e: DropDownListChangeEvent) => onResponseChange(e, "A247")}
                    />

                    <Field
                        label={"Drugs given at Clinic"}
                        name={"A248"}
                        style={{ height: "150px" }}
                        component={FormTextArea}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={{ label: "Responses...", value: "" }}
                        textField="label"
                        data={[
                            { label: "Responses...", value: "" },
                            { label: "Drugs given", value: "Drug Name: \rDrug concentration (mg): \rDose administered (in mg, mL, or tube): \rRoute of Administration (oral, topical, injection SC, injection IM): "},
                            ]}
                        onChange={(e: DropDownListChangeEvent) => onResponseDrugChange(e, "A248")}
                    />

                    <Field
                        label={"Drugs dispensed"}
                        name={"A249"}
                        style={{ height: "150px" }}
                        component={FormTextArea}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={{ label: "Responses...", value: "" }}
                        textField="label"
                        data={[
                            { label: "Responses...", value: "" },
                            { label: "Drugs dispensed", value: "Drug Name: \rDrug concentration (mg): \rDirections for owner, including route of administration: \rNumber dispensed (in mL, tablets, or tubes): \rDuration of therapy (days): " },
                        ]}
                        onChange={(e: DropDownListChangeEvent) => onResponseDrugChange(e, "A249")}
                    />

                    <Field
                        label={"Client Recommendation"}
                        name={"A250"}
                        style={{ height: "110px" }}
                        component={FormTextArea}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={"Responses..."}
                        data={["Responses...", "Behaviour recommendations", "Booster vaccination required",
                            "Dental procedure required", "Medical care appointment", "Recheck at a future Outreach clinic",
                            "Repeat parasite treatment", "Spay or neuter required", "Weight loss"]}
                        onChange={(e: DropDownListChangeEvent) => onResponseChange(e, "A250")}
                    />

                    <Field
                            label={"Dietary Recommendation" + reqdSuffix}
                        name={"A251"}
                        component={FormTextArea}
                        style={{ height: "110px" }}
                        validator={partialExam ? [] : requiredValidator}
                    />
                    <DropDownList
                        className={"sds-normal"}
                        defaultValue={"Responses..."}
                        data={["Responses...", "Dental diet", "Growth diet", "Hypoallergenic diet", "Large breed diet",
                            "Mobility diet", "Senior diet", "Weight loss"]}
                        onChange={(e: DropDownListChangeEvent) => onResponseChange(e, "A251")}
                    />

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Services Performed
                    </legend>

                    <StackLayout gap={48}>
                        <Field
                            label={"Nail Trim"}
                            name={"A252"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N252"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Ear Clean"}
                            name={"A253"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N253"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Anal Gland Expression"}
                            name={"A254"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N254"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"Intestinal Deworming"}
                            name={"A338"}
                            component={FormDropDownList}
                            data={["Yes", "No"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N338"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <StackLayout gap={48}>
                        <Field
                            label={"New Microchip # Implanted"}
                            name={"A339"}
                            component={FormInput}
                            onChange={(e: InputChangeEvent) => onInputChange(e, "A339")}
                        />
                        <Field
                            label={"Notes"}
                            name={"N339"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Follow Up Services Requested
                    </legend>

                        <StackLayout gap={48} style={{marginTop:"-16px"} }>
                        <Field
                            label={"Services Requested"}
                            name={"A382"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={["Spay Neuter Request", "External Referral Request", "Booster Request", "Microchip Request"]}
                        />
                        <Field
                            label={"Notes"}
                            name={"N382"}
                            component={FormInput}
                        />
                    </StackLayout>

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Client Communication
                    </legend>

                    <div className={"sds-clientcomm sds-hstack"}>
                        <StackLayout gap={48}>
                            <span />
                            <span>Discussed</span>
                            <span>Handout</span>
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Core Vaccines</span>
                            <Field
                                name={"A255"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A256"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Vaccine Side Effects</span>
                            <Field
                                name={"A257"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A258"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Vaccine Schedule</span>
                            <Field
                                name={"A259"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A260"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Parasites / Deworming</span>
                            <Field
                                name={"A261"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A262"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Spay / Neuter</span>
                            <Field
                                name={"A263"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A264"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Dental Care</span>
                            <Field
                                name={"A265"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A266"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Exercise</span>
                            <Field
                                name={"A267"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A268"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Grooming</span>
                            <Field
                                name={"A269"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A270"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Training/Behavior</span>
                            <Field
                                name={"A271"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A272"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Fleas</span>
                            <Field
                                name={"A273"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A274"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Diet</span>
                            <Field
                                name={"A275"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A276"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Change in smoking behaviour</span>
                            <Field
                                name={"A277"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A278"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                        <StackLayout gap={48}>
                            <span>Physical exercise recommendation</span>
                            <Field
                                name={"A279"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                            <Field
                                name={"A280"}
                                component={FormCheckbox}
                                style={{ borderColor: "black" }}
                            />
                        </StackLayout>

                    </div>

                    <Field
                        label={"Intro to Human Health Care Professional"}
                        name={"A281"}
                        component={FormDropDownList}
                        data={["Yes", "No"]}
                    />

                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Personnel
                    </legend>

                    {(!newMicrochip) &&
                        <StackLayout gap={48}>
                            <Field
                                label={"Existing Microchip Number"}
                                name={"A302"}
                                component={FormInput}
                            />
                            <Field
                                label={"Notes"}
                                name={"N302"}
                                component={FormInput}
                            />
                        </StackLayout>
                    }

                    <Field
                            label={"Tech initials (just one required)" + reqdSuffix}
                        name={"A283"}
                        component={FormInput}
                        validator={partialExam ? [] : requiredValidator}
                    />

                </fieldset>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ExamNewRecord;

