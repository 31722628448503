﻿import * as React from "react";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
    getSelectedState,
    GridProps
} from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import {
    orderBy,
    SortDescriptor,
} from "@progress/kendo-data-query";

import Pet from './Pet'
export interface InlinePetsGridProps extends GridProps {
    data: any;
    returnToLabel: string;
}

const cellWithCheck = (props: GridCellProps) => {
    const active = props.dataItem.active;

    const style = {
        color: "green",
    };
    return (
        <td style={style}>
            {active === "1" ? <span className="k-icon k-font-icon k-i-check" /> : null}
        </td>
    );
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const initialSort: Array<SortDescriptor> = [
    { field: "name", dir: "asc" },
];

const InlinePetsGrid = ({
    data,
    returnToLabel,
    ...gridProps
}: InlinePetsGridProps) => {

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const [sort, setSort] = React.useState(initialSort);

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };

    return (
        <React.Fragment>
            {(selectedUID.length === 0) ?
                (<Grid
                    {...gridProps}
                    className="inlinePetGrid sds-selectable"
                    data={orderBy(data.map((item: any) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                    })), sort)}
                    resizable={true}
                    reorderable={true}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        mode: "single",
                    }}
                    onSelectionChange={handleSelectionChange}
                    size={"small"}
                >
                    <GridColumn field="name" title="Name"/>
                    <GridColumn field="ownerName" title="Owner" />
                    <GridColumn field="species" title="Species" />
                    <GridColumn field="gender" title="Gender" />
                    <GridColumn field="active" title="Active" cell={cellWithCheck} />
                </Grid>) :
                (<Dialog
                    closeIcon={true}
                    onClose={() => setSelectedUID("")}
                    contentStyle={{ width: "1170px" }}>
                    <Pet
                        petID={selectedUID}
                        returnLabel={returnToLabel}
                        onReturn={() => setSelectedUID("")}
                        hideExams={true }
                    />
                </Dialog>)
            }
        </React.Fragment>
    );
}
export default InlinePetsGrid;
