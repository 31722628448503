import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
} from "@progress/kendo-react-buttons";

import { FormInput, FormCheckbox } from "./FormComponents";
import { requiredValidator, emailValidator, phoneValidator, userNameValidator } from "./validators";

import { useNewAgencyMutation } from '../store/sunbright-api';

import { useAppSelector } from "../store/store";


export interface AgencyNewProps {
    agencyData?: any;
    onReturn: any;
}
const AgencyNew = ({
    agencyData,
    onReturn,
}: AgencyNewProps) => {

    const auth = useAppSelector((state: any) => state.auth);
    const personID = (auth && (auth.user !== undefined) && (auth.user.id !== undefined)) ? auth.user.id : -1;

    const [newAgency, response] = useNewAgencyMutation();

    const PersonNewForm = React.useRef<any>(null);

    const submitForm = (event: FormSubmitClickEvent) => {

        const { isValid } = event;

        if (isValid) {
            const postExam = PersonNewForm.current.values;

            postExam.uid = agencyData ? agencyData.uid : 0;

            newAgency(postExam)
                .unwrap()
                .then((payload) => { })
                .catch((error) => {
                    console.log(error)
                });
            onReturn(true);
        }
    }

    return (
        <React.Fragment>
            <div
                style={{ overflow: "auto", marginLeft: "16px" }}
                className={"sds-bold"}
            >
                <Form
                    ref={PersonNewForm}
                    initialValues={agencyData}
                    onSubmitClick={ submitForm }
                    render={(formRenderProps: FormRenderProps) => (
                        <div>
                            <Toolbar>
                                <Button
                                    icon="upload"
                                    onClick={formRenderProps.onSubmit}
                                >
                                    Submit
                                </Button>
                                <Button
                                    icon="cancel"
                                    onClick={() => (onReturn ? onReturn(false) : null)}
                                >
                                    Cancel
                                </Button>
                            </Toolbar>
                            <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{overflow: "auto", marginLeft: "16px", marginRight: "32px" }}>
                                    <div className={"sds-questionnaire"}>
                                        <fieldset
                                            className={"k-form-fieldset"}
                                        >
                                            <StackLayout gap={16}>
                                                <Field
                                                    label={"Name*"}
                                                    name={"name"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Phone"}
                                                    name={"mobilePhone"}
                                                    component={FormInput}
                                                    validator={phoneValidator}
                                                />
                                                <Field
                                                    label={"Email*"}
                                                    name={"email"}
                                                    component={FormInput}
                                                    validator={[emailValidator, requiredValidator]}
                                                />
                                                <div style={{ marginTop: "20px" }}>
                                                    <Field
                                                        style={{ borderColor: "black" }}
                                                        label={"Active"}
                                                        name={"active"}
                                                        defaultValue={true}
                                                        component={FormCheckbox}
                                                    />
                                                </div>
                                            </StackLayout>
                                            <StackLayout gap={16}>
                                                <Field
                                                    label="Address*"
                                                    name={"address1"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                               />
                                                <Field
                                                    label="City*"
                                                    name={"city"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label="Province*"
                                                    name={"province"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label="Postal Code"
                                                    name={"postalCode"}
                                                    className={"sds-uppercase"}
                                                    component={FormInput}
                                                />
                                            </StackLayout>
                                        </fieldset>
                                    </div>
                                </FormElement>
                                </div>
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
      );
}
export default AgencyNew;