import * as React from "react";

import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { FormDateTimePicker, FormDropDownList } from "../FormComponents";
import { DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs/"

import { useGetVetNamesQuery } from '../../store/sunbright-api';
import { Button } from "@progress/kendo-react-buttons";

export interface ExamNewScheduleProps {
    formRenderProps: FormRenderProps;
}

const ExamNewSchedule = ({
    formRenderProps,
}: ExamNewScheduleProps) => {

    const { data: vetnames, error: vetnamesError, isLoading: vetnamesLoading } = useGetVetNamesQuery(1);

    const handleFromTimeChange = (e: DateTimePickerChangeEvent) => {
        if (e.value) {
            formRenderProps.onChange("A334", { value: e.value });
        }
    }

    const deleteFromTime = () => {
        formRenderProps.onChange("A333", { value: "" });
        formRenderProps.onChange("A334", { value: "" });
    }
    const deleteToTime = () => {
        formRenderProps.onChange("A334", { value: "" });
    }

    return (
        <React.Fragment>

            <div className={"sds-questionnaire"}>

                <fieldset
                    style={{}}
                    className={"k-form-fieldset"}
                >
                    <legend
                        style={{ paddingTop: "24px" }}
                        className={"k-form-legend"}>
                        Schedule
                    </legend>

                    <StackLayout gap={24}>
                        <span>
                            <Field
                                label={"From"}
                                name={"A333"}
                                component={FormDateTimePicker}
                                placeholder=""
                                onChange={handleFromTimeChange }
                            />
                            <Button
                                style={{ float: "right", width: "28px" }}
                                type="button"
                                icon="close"
                                onClick={deleteFromTime}
                           />
                        </span>
                        <span>
                            <Field
                                label={"To"}
                                name={"A334"}
                                component={FormDateTimePicker}
                                placeholder=""
                            />
                            <Button
                                style={{ float: "right", width: "28px" }}
                                type="button"
                                icon="close"
                                onClick={deleteToTime}
                            />
                        </span>
                    </StackLayout>
                    <Field
                        label={"Veterinarian (optional)"}
                        name={"vetnamecombo"}
                        component={FormDropDownList}
                        data={vetnames}
                        defaultValue={{ uid: 0, name: formRenderProps.valueGetter("A335") } }
                        textField={"name"}
                    />
               </fieldset>
            </div>
        </React.Fragment>
    );
}
export default ExamNewSchedule;

