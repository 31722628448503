import * as React from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { Field } from "@progress/kendo-react-form";

import { FormMultiSelect, FormInput } from "../FormComponents";

import NavigateToLogin from "../NavigateToLogin";

import { useGetServicesQuery } from '../../store/sunbright-api';

const ExamNewServices = () => {

    const requestedServices: string[] = ["Spay Neuter Request", "External Referral Request", "Booster Request"];

    const { data, error, isLoading } = useGetServicesQuery("");

    return (
        <React.Fragment>
            {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? (
                <div className={"sds-questionnaire"}>
                    <fieldset
                        className={"k-form-fieldset"}
                    >
                        <legend
                            style={{ paddingTop: "24px" }}
                            className={"k-form-legend"}>
                            Services Performed
                        </legend>
                        <span>Select any services that were <b>not</b> performed by a CVO veterinary team as part of an exam.</span>

                        <Field
                            label={""}
                            name={"A324"}
                            component={FormMultiSelect}
                            autoClose={false}
                            header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                            data={data.map((item: any) => item.name)}
                            />
                        <Field
                            label={"Notes"}
                            name={"N324"}
                            component={FormInput}
                        />
                   </fieldset>
                </div>) : null}
        </React.Fragment >
    );
}
export default ExamNewServices;

