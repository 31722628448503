﻿import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";

import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";

import { useGetPetQuery } from '../store/sunbright-api';

import NavigateToLogin from "./NavigateToLogin";

import InlineExamsGrid from './Exam/InlineExamsGrid'

export interface PetFormProps {
    petID: string;
    returnLabel: string;
    onReturn?: any;
    hideExams: boolean;
}

const Pet = ({
    petID,
    returnLabel,
    onReturn,
    hideExams,
}: PetFormProps) => {
    const { data, error, isLoading } = useGetPetQuery(petID);

    return (
        <React.Fragment>
            <div className="sds-window">
                {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? (
                    <div>
                        <Toolbar>
                        <ButtonGroup>
                                <Button
                                    icon="undo"
                                    onClick={(e) => onReturn() }
                                >
                                    {returnLabel}
                                </Button>
                        </ButtonGroup>
                    </Toolbar>
                        <Form
                                initialValues={{
                                    petName: data.petName,
                                    petSpecies: data.species,
                                    petBreed: data.breed,
                                    petBreedOther: data.breedOther,
                                    petColor: data.color,
                                    petDob: (data.dob && (data.dob.length > 0)) ? (new Date(data.dob)).toLocaleDateString() : null,
                                    petGender: data.gender,
                                    petWeight: data.weight,
                                    petMicrochipTag: data.microchipTag,
                                    petMicrochip: data.microchipRef,
                                    cwAgency: data.agencyGroup,
                                    ownerName: data.owner.name,
                                    ownerMobile: data.ownerMobile,
                                    ownerLocal: data.owner.localPhone,
                                ownerEmail: data.owner.email,
                                ownerAddress: data.owner.address1,
                                ownerCity: data.owner.city,
                                ownerProvince: data.owner.province,
                                ownerPostalCode: data.owner.postalCode,

                            }}
                            render={(formRenderProps: FormRenderProps) => (
                                    <div>
                                    <FormElement style={{margin:"16px"}}>
                                        <fieldset
                                            className={"k-form-fieldset"}
                                        >
                                            <legend className={"k-form-legend"}>Pet</legend>
                                            <StackLayout gap={16}>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Name</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petName"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Species</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petSpecies"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Breed</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petBreed"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Breed (Other)</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petBreedOther"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Color</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petColor"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                            </StackLayout>
                                            <StackLayout gap={16}>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Birthdate</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petDob"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Gender</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petGender"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Weight (kg)</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petWeight"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Microchip Tag</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petMicrochipTag"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Microchip #</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"petMicrochip"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                            </StackLayout>
                                        </fieldset>
                                        <fieldset
                                            className={"k-form-fieldset"}
                                        >
                                            <legend className={"k-form-legend"}>Owner</legend>
                                            <StackLayout gap={16}>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Name</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerName"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Mobile Phone</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerMobile"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Local Phone</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerLocal"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Email</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerEmail"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                            </StackLayout>
                                            <StackLayout gap={16}>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Address</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerAddress1"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">City</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerCity"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Province</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerProvince"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                                <FieldWrapper>
                                                    <Label className="k-form-label">Postal Code</Label>
                                                    <div className="k-form-field-wrap">
                                                        <Field
                                                            name={"ownerPostal"}
                                                            component={Input}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </FieldWrapper>
                                            </StackLayout>
                                        </fieldset>
                                    </FormElement>
                                    {!hideExams &&
                                        (<div>
                                            <legend className={"k-form-legend"}>Exams</legend>
                                            < InlineExamsGrid
                                            data={data.exams}
                                            petTitle={data.petName + " (" + data.owner.name + ")" }
                                                returnToLabel="Return to Pet"
                                            />
                                        </div>)}
                                    </div>
                                )}
                            />
                    </div>) : null}
                </div>
       </React.Fragment>
    );
};
export default Pet;
