import { Chip } from "@progress/kendo-react-buttons";
import { StackLayout } from "@progress/kendo-react-layout";
import { Field } from "@progress/kendo-react-form";

import { FormDropDownList, FormInput, FormDatePicker } from "../FormComponents";
import FloatingContainerLabel from "../FloatingContainerLabel";
import { requiredValidator } from "../validators";

export interface ExamVaccineRowProps {
    vac: any;
    idx: number;
    species: string;
    vaccines: any[];
}

export const ExamVaccineRow = ({
    vac,
    idx,
    species,
    vaccines,
}: ExamVaccineRowProps) => {

    const label = (
        <Chip
            text={`Vaccine #${idx + 1}`}
            fillMode="outline"
        />
    );

    return (
        <FloatingContainerLabel label={label}>
            <Field
                name={vac.type}
                component={FormDropDownList}
                data={["*No*"].concat(vaccines)}
                validator={requiredValidator}
            />
            <StackLayout gap={24} style={{ marginLeft: "24px" }}>
                <Field
                    label={"Tag"}
                    name={vac.tag}
                    component={FormInput}
                    disabled={false}
                />
                <Field
                    label={"Due"}
                    name={vac.due}
                    component={FormDatePicker}
                    placeholder=""
                    disabled={false}
                />
                <Field
                    label={"Location"}
                    name={vac.location}
                    component={FormDropDownList}
                    data={["RFSQ", "LFSQ", "RHSQ", "LHSQ"]}
                    disabled={false}
                />
            </StackLayout>
        </FloatingContainerLabel>
    );
};
