import * as React from 'react';
import {
    Drawer,
    DrawerContent,
    DrawerItem,
    DrawerItemProps,
    DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";

import PetsGrid from "./PetsGrid";
import AgenciesGrid from "./AgenciesGrid";
import LeafletsGrid from "./LeafletsGrid";
import UserGuideGrid from "./UserGuideGrid";
import PersonsGrid from "./PersonsGrid";
import ExamsGrid from "./Exam/ExamsGrid";
import ScheduleGrid from "./ScheduleGrid";
import VaccinesGrid from "./VaccinesGrid";
import ServicesGrid from "./ServicesGrid";
import FindingsGrid from "./FindingsGrid";
import EventsGrid from "./EventsGrid";

import { useAppSelector } from "../store/store";

const userItems = [
    { text: "Exams", svg: "table.svg", group: "", groupheader: "true"},
    // { text: "Events", icon: "k-i-calendar", group: "", groupheader: "true" },
    { text: "Appointments", icon: "k-i-calendar", group: "", groupheader: "true" },
    { separator: true },
    { text: "Leaflets", svg: "files.svg", group: "", groupheader: "true" },
    { text: "User Guide", icon: "k-i-help", group: "", groupheader: "true" },
];

const adminItems = [
    { text: "Exams", svg: "table.svg", group: "", groupheader: "true" },
    // { text: "Events", icon: "k-i-calendar", group: "", groupheader: "true" },
    { text: "Appointments", icon: "k-i-calendar", group: "", groupheader: "true" },
    { separator: true },
    { text: "Leaflets", svg: "files.svg", group: "", groupheader: "true" },
    { text: "User Guide", icon: "k-i-help", group: "", groupheader: "true" },
    { separator: true },
    { text: "Admin", icon: "k-i-gears", group: "admin", groupheader: "true" },
    { text: "Agencies", svg: "buildings.svg", group: "admin", groupheader: "false" },
    //{ text: "Users", icon: "k-i-user", group: "admin", groupheader: "false"},
    { text: "People", icon: "k-i-myspace", group: "admin", groupheader: "false" },
    { text: "Pets", svg: "paw-print.svg", group: "admin", groupheader: "false" },
    { separator: true },
    { text: "Services", svg: "stethoscope.svg", group: "admin", groupheader: "false" },
    { text: "Vaccines", svg: "syringe.svg", group: "admin", groupheader: "false" },
    { separator: true },
    { text: "Event Follow Up", svg: "check-fat.svg", group: "", groupheader: "true" },
];

const CustomItem = (props: DrawerItemProps) => {
    return (
        <DrawerItem {...props}>
            {props.icon ? <span className={"k-icon k-font-icon " + props.icon} role="presentation" /> : <img src={props.svg} alt="{props.text}" />}
            <span className={"k-item-text " + (props.groupheader === "false" ? "sds-drawerSub" : "")}>{props.text}</span>
        </DrawerItem>
    );
};

const DrawerRouterContainer = (props:any) => {
    const auth = useAppSelector((state) => state.auth);
    const [items, setItems] = React.useState((auth && (auth.user !== undefined) && auth.user.role && (auth.user.role.includes("Admin"))) ? adminItems : userItems);

    const [expanded, setExpanded] = React.useState(true);
    const [selectedText, setSelectedText] = React.useState(auth && (auth.user !== undefined) && auth.user.role &&
        (auth.user.role.includes("Admin") || auth.user.role.includes("Case Worker")) ? "Exams" : "Appointments");
    const [selectedGroup, setSelectedGroup] = React.useState("");

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const onSelect = (e: DrawerSelectEvent) => {
        setSelectedText(e.itemTarget.props.text);
        if ((e.itemTarget.props.group === selectedGroup) && (e.itemTarget.props.groupheader === "true")) {
            setSelectedGroup("");
        } else {
            setSelectedGroup(e.itemTarget.props.group);
        }
    };

    return (
        <div>
            <Drawer
                expanded={expanded}
                position={"start"}
                mode={"push"}
                mini={true}
                items={items.map((item) => ({
                    ...item,
                    selected: item.text === selectedText,
                    style: ((item.groupheader === "true") || (item.group === selectedGroup)) ? { display: "flex" } : { display: "none" },
                }))}
                item={CustomItem}
                onSelect={onSelect}
            >
                <DrawerContent>
                    {(selectedText === "Exams") && (
                        <ExamsGrid />
                    )}
                    {(selectedText === "Appointments") && (
                        <ScheduleGrid />
                    )}
                    {(selectedText === "Leaflets") && (
                        <LeafletsGrid />
                    )}
                    {(selectedText === "User Guide") && (
                        <UserGuideGrid />
                    )}
                    {(selectedText === "Admin") && (
                        <AgenciesGrid />
                    )}
                    {(selectedText === "Agencies") && (
                        <AgenciesGrid />
                    )}
                    {(selectedText === "People") && (
                        <PersonsGrid />
                    )}
                    {(selectedText === "Pets") && (
                        <PetsGrid />
                    )}
                    {(selectedText === "Services") && (
                        <ServicesGrid />
                    )}
                    {(selectedText === "Findings") && (
                        <FindingsGrid />
                    )}
                    {(selectedText === "Vaccines") && (
                        <VaccinesGrid />
                    )}
                    {(selectedText === "Event Follow Up") && (
                        <EventsGrid />
                    )}
                </DrawerContent>
            </Drawer>
            <div>
                
                <Button
                    icon={expanded === true ? "arrow-double-60-left" : "arrow-double-60-right"}
                    onClick={handleExpandClick}
                    style={{ position: "absolute", top:"41px", left:"0px" }}
                />                
            </div>
        </div>
    );
};

export default DrawerRouterContainer;