import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";

import {
    Grid,
    GridColumn,
    GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";

import {
    process,
    State,
} from "@progress/kendo-data-query";

import NavigateToLogin from "./NavigateToLogin";

import { useGetServicesQuery } from '../store/sunbright-api';

const initialDataState: State = {
    filter: {
        logic: "and",
        filters: [
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};

const ServicesGrid = (props: any) => {

    const { data, error, isLoading } = useGetServicesQuery("");

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    React.useEffect(() => {
        setGridHeight((window.innerHeight - 100) + "px");
    }, [data]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [dataState, setDataState] = React.useState<State>(initialDataState);

    return (
        <React.Fragment>
            <div className="sds-gridHeader">Services</div>
            {error ? (<NavigateToLogin/>) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? (
                <Grid
                    style={{ height: gridHeight}}
                    className="serviceGrid"
                    data={process(data, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => { setDataState(e.dataState); }}
                    sortable={true}
                    filterable={true}
                    resizable={true}
                    reorderable={true}
                    size={"small"}
                >
                    <GridColumn field="name" title="Name" filterable={true} filter="text" />
                    <GridColumn field="description" title="Description" filterable={false}/>
                </Grid>) : null}
        </React.Fragment>
    );
}
export default ServicesGrid;
