import { Field } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";

import { FormInput, FormDropDownList } from "../FormComponents";
import { requiredValidator } from "../validators";

const ExamNewEligibility = () => {

    return (
        <div className={"sds-questionnaire"}>
            <span></span>
           <fieldset
                className={"k-form-fieldset"}
            >
                <StackLayout gap={48}>
                    <Field
                        label={"Client is homeless, transitionally-housed or vulnerable-housed (based on the COH definition of homeless). If NO, please contact the regional director.*"}
                        name={"A144"}
                        data={["Yes", "No"]}
                        component={FormDropDownList}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N144"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Client is receiving government social support, or has no income; or if the client has no ID - is making regular use of shelter or agency services*"}
                        name={"A145"}
                        data={["Yes", "No"]}
                        component={FormDropDownList}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N145"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Client has a regular relationship with a clinic or veterinarian. An existing veterinarian-client-patient relationship (VCPR), with their pet examined or treated by a veterinarian in the past 12 months. If YES, please contact the regional director.*"}
                        name={"A340"}
                        data={["Yes", "No"]}
                        component={FormDropDownList}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N340"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Client's pet has received emergency care, spay neuter services, a bylaw order, or care at a humane society in the past 12 months.*"}
                        name={"A147"}
                        data={["Yes", "No"]}
                        component={FormDropDownList}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N147"}
                        component={FormInput}
                    />
                </StackLayout>
            </fieldset>
        </div>
    );
}
export default ExamNewEligibility;
