﻿import * as React from "react";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridSortChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
    GridProps
} from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import {
    orderBy,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
} from "@progress/kendo-data-query";

import Exam from './Exam'
export interface InlineExamsGridProps extends GridProps {
    data: any;
    petTitle: string;
    examIdentifier?: string;
    returnToLabel: string;
}

const cellWithColor = (props: GridCellProps) => {
    const status = props.dataItem.Status;
    const color = (status === "Closed" || status === "Pending") ? "#999" : ((status === "Submitted") ? "#5cb85c" : "#5bc0de");      // gray : green : blue

    const style = {
        color: color,
        fontWeight: "900"
    };
    const field = props.field || "";
    return (
        <td style={style} data-grid-col-index={0}>
            {props.dataItem[field]}
        </td>
    );
};

const initialSort: Array<SortDescriptor> = [
    { field: "submitted", dir: "desc" },
];

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const InlineExamsGrid = ({
    data,
    petTitle,
    returnToLabel,
    examIdentifier,
    ...gridProps
}: InlineExamsGridProps) => {

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const [sort, setSort] = React.useState(initialSort);
    const [showExam, setShowExam] = React.useState<boolean>(false);

    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [{ field: "id", operator: "ne", value: examIdentifier ? examIdentifier : ""}]
    };

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);
        setSelectedUID(Object.keys(newState)[0]);

        setShowExam(true);
    };

    return (
        <React.Fragment>
            {!showExam ?
                (<Grid
                    {...gridProps}
                    className="inlineExamGrid sds-selectable"
                    data={orderBy(filterBy(data.map((item: any) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                    })), initialFilter), sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}
                    resizable={true}
                    reorderable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        mode: "single",
                    }}
                    onSelectionChange={(e) => handleSelectionChange(e)}
                    size={"small"}
                >
                    <GridColumn field="status" title="Status" cell={cellWithColor} />
                    <GridColumn field="services" title="Services" />
                    <GridColumn field="petWeight" title="Weight" />
                    <GridColumn field="caseWorker" title="Case Worker" />
                    <GridColumn field="submitted" title="Submitted" format="{0:MMM d yyyy}" />
                    <GridColumn field="opened" title="Performed" format="{0:MMM d yyyy}" />
                    <GridColumn field="veterinarian" title="Veterinarian" filterable={true} />
                    <GridColumn field="id" title="ID" filterable={false} />
                </Grid>) :
                (<Dialog
                    title={petTitle}
                    contentStyle={{ width: "1170px" }}
                    onClose={(e) => setShowExam(false)}
                >
                    <Exam
                        examID={selectedUID}
                        viewOnly={true}
                        returnLabel={returnToLabel}
                        onReturn={() => setSelectedUID("")}
                    />
                </Dialog>)
            }
        </React.Fragment>
    );
}
export default InlineExamsGrid;
