import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
import { Editor } from "@progress/kendo-react-editor";
import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";

export interface FindingsProps {
    legend: string;
    fields: any;
    info: any[];
    formatFindingField: any;
    qWidth: string,
    cellWithHtml: any,
}

const Findings = ({
    legend,
    fields,
    info,
    formatFindingField,
    qWidth,
    cellWithHtml,
    ...windowProps
}: FindingsProps) => {

    return (
        <React.Fragment>
            <fieldset className={"k-form-fieldset"} >
                <legend className={"k-form-legend"} style={{ paddingTop: "32px" }}>{legend}</legend>
                {fields.map((field: any) => {
                    return ((field.t === "info") ?
                        (<div>
                            <br />
                            <div className="k-form-field-wrap"
                                style={{ marginTop: info[field.i - 1].gap, color: "blue", height: field.i ? info[field.i - 1].h : "" }}>
                                {(field.i && info[field.i - 1].text) ? info[field.i - 1].text : ""}
                            </div>
                        </div>)
                        :
                        ((field.t === "finding") ?
                            (<FieldWrapper>
                                <div className="k-form-field-wrap" style={{ backgroundColor: "lightblue" }}>
                                    <Label className="k-form-label">{field.result.q}</Label>
                                    <Field
                                        name={"A" + field.result.findingID}
                                        defaultValue={formatFindingField(field) + " " + field.result.n}
                                        style={{
                                            fontWeight: "bold",
                                            color: field.result.alert ? (field.result.a.startsWith(field.result.alert) ? "red" : "green") : "",
                                            height: field.result.findingID === 323 ? "200px" :
                                                (field.result.findingID === 310 ? "350px" : "")
                                        }}
                                        disabled={!field.result.n.includes('\n')}
                                        readOnly={true}
                                        component={(field.result.a.includes('\n') || (field.result.n.includes('\n'))) ? TextArea : TextBox}
                                    />
                                </div>
                            </FieldWrapper>) :
                            ((field.t === "grid") ?
                                (<Grid
                                    data={field.results}
                                    style={{ fontWeight: "bold" }}
                                    className={"sds-no-header sds-no-yscroll"}
                                >
                                    <GridColumn field="q" width={qWidth} />
                                    <GridColumn field="a" cell={cellWithHtml} />
                                    <GridColumn field="n" cell={cellWithHtml} />
                                </Grid>) :
                                (<Grid
                                    data={field.results}
                                    style={{ fontWeight: "bold" }}
                                >
                                    <GridColumn field="q" title=" " width={qWidth} />
                                    <GridColumn field="a1" title="Discussed" cell={cellWithHtml} />
                                    <GridColumn field="a2" title="Handout" cell={cellWithHtml} />
                                </Grid>)
                            )))
                })}
            </fieldset>
        </React.Fragment>
    );
}

export interface ExamPDFProps {
    examData: any;
    info: any[];
    formatFindingField: any;
    qWidth: string,
    cellWithHtml: any,
}

const ExamPDF = ({
    examData,
    info,
    formatFindingField,
    qWidth,
    cellWithHtml,
    ...windowProps
}: ExamPDFProps) => {

    return (
        <React.Fragment>
            <StackLayout gap={16}>
                <div style={{ textAlign: "left" }}>
                    <img src="CVO-Logo-Header-200.png" alt="CVO"/>
                </div>
                <div style={{ textAlign: "center" }} >
                    <div>{examData.regionName + " Community Veterinary Outreach Mobile"}</div>
                    <div>{examData.vetaddress}</div>
                    <div>{"Ph: " + examData.vetphone}</div>
                    <div>{"email: " + examData.regionEmail}</div>
                </div>
            </StackLayout>
            <Form
                initialValues={{
                    ownerfirst: examData.ownerfirst,
                    ownerlast: examData.ownerlast,
                    ownermobile: examData.ownermobile,
                    owneremail: examData.owneremail,
                    petname: examData.petname,
                    petspecies: examData.petspecies,
                    petbreed: examData.petbreed,
                    petcolor: examData.petcolor,
                    petdob: (examData.petdob && (examData.petdob.length > 0)) ? (new Date(examData.petdob)).toLocaleDateString() : null,
                    petgender: examData.petgender,
                    petweight: examData.petweight,
                    petmicrochipref: examData.petmicrochipref,
                }}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{}}>
                        <fieldset
                            className={"k-form-fieldset"}
                        >
                            <legend className={"k-form-legend"}>Owner</legend>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">First Name</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownerfirst"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Last Name</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownerlast"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Mobile Phone</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownermobile"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Email</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"owneremail"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                            </StackLayout>
                        </fieldset>
                        <fieldset
                            className={"k-form-fieldset"}
                        >
                            <legend className={"k-form-legend"}>Pet</legend>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">Name</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petname"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Species</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petspecies"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Breed</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petbreed"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Color</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petcolor"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                            </StackLayout>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">Birthdate</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petdob"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Gender</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petgender"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Weight (kg)</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petweight"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Microchip #</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petmicrochipref"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                            </StackLayout>
                        </fieldset>
                        <Findings
                            fields={examData.examRecord.fields}
                            legend="Exam Data"
                            info={info}
                            qWidth={qWidth}
                            cellWithHtml={cellWithHtml}
                            formatFindingField={formatFindingField}
                        />
                        <Findings
                            fields={examData.vaccinations.fields}
                            legend="Vaccinations"
                            info={info}
                            qWidth={qWidth}
                            cellWithHtml={cellWithHtml}
                            formatFindingField={formatFindingField}
                        />
                        <Findings
                            fields={examData.comments.fields}
                            legend="Comments"
                            info={info}
                            qWidth={qWidth}
                            cellWithHtml={cellWithHtml}
                            formatFindingField={formatFindingField}
                        />
                    </FormElement>
                )}
            />

        </React.Fragment>
    );
};
export default ExamPDF;
