import * as React from "react";

import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

import { FormDatePicker, FormTextArea } from "../FormComponents";
import { ExamVaccineRow }  from "./ExamVaccineRow";
import { useGetVaccinesQuery } from '../../store/sunbright-api';

import { requiredValidator } from "../validators";

export interface ExamNewVaccinationsProps {
    species: string;
    vac2: string;
    vac3: string;
    vac4: string;
}

interface Vaccine {
    type: string;
    tag: string;
    due: string;
    location: string;
}

const ExamNewVaccinations = ({
    species,
    vac2, vac3, vac4,
}: ExamNewVaccinationsProps) => {

    const speciesEnum = (species === "Cat") ? 1 : ((species === "Dog") ? 2 : 0);
    const { data: vaccines, error: vaccinesError, isLoading: vaccinesLoading } = useGetVaccinesQuery(speciesEnum);

    const allVaccines: Vaccine[] = [
        { type: "A311", tag: "A312", due: "A313", location: "A314" },
        { type: "A315", tag: "A316", due: "A317", location: "A318" },
        { type: "A319", tag: "A320", due: "A321", location: "A322" },
        { type: "A341", tag: "A342", due: "A343", location: "A344" },
        { type: "A345", tag: "A346", due: "A347", location: "A348" },
    ];

    const [showIndex, setShowIndex] = React.useState<number>(vac4 ? 4 : (vac3 ? 3 : (vac2 ? 2 : 1) ));

    const addVaccine = () => {
        if (showIndex < 4) {
            setShowIndex(showIndex + 1);
        }
    }

    return (
        <React.Fragment>

            <div>
                <fieldset
                    style={{}}
                    className={"k-form-fieldset"}
                >
                    <legend className={"k-form-legend"} style={{ marginTop: "24px", color: "steelblue" }}>
                        Vaccines
                        {(showIndex < 4) &&
                            <Button
                                type="button"
                                icon="add"
                                fillMode="flat"
                                style={{ marginLeft: 10 }}
                                onClick={addVaccine}
                            >Add Vaccine
                            </Button>
                        }
                    </legend>

                    {allVaccines.filter((vac, idx) => (idx <= showIndex)).map((vac, idx) => (
                        <ExamVaccineRow
                            vac={vac}
                            idx={idx}
                            species={species}
                            vaccines={vaccines }
                        />
                    ))}

                    <legend
                        className={"k-form-legend"} style={{ marginTop: "24px", color: "steelblue" }}>
                        Date
                    </legend>
                   <Field
                        name={"A336"}
                        component={FormDatePicker}
                        placeholder=""
                        validator={requiredValidator}
                    />
                    <legend
                        className={"k-form-legend"} style={{ marginTop: "24px", color: "steelblue" }}>
                        Notes
                    </legend>
                   <Field
                        name={"A323"}
                        component={FormTextArea}
                        style={{height:"200px"} }
                    />
               </fieldset>
            </div>
        </React.Fragment>
    );
}
export default ExamNewVaccinations;

