﻿import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";
import { StackLayout, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";

import InlineExamsGrid from './InlineExamsGrid'
import NavigateToLogin from "../NavigateToLogin";
import { useGetPetQuery } from '../../store/sunbright-api';

export interface ExamViewClientPetProps {
    examID: string;
    petID: string;
    examData: any;
    viewOnly: boolean;
    category: string,
}

const ExamViewClientPet = ({
    examID,
    petID,
    examData,
    viewOnly,
    category,
    ...windowProps
}: ExamViewClientPetProps) => {

    const { data: petData, error, isLoading } = useGetPetQuery(petID);

    const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    return (
        <React.Fragment>
            {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : petData ? (
               <div>
            <Form
                initialValues={{
                    status: examData.status,
                    opened: examData.opened ? examData.opened.toLocaleDateString("en-US", dateOptions).substring(5) : "",
                    submitted: examData.submitted ? examData.submitted.toLocaleDateString("en-US", dateOptions).substring(5) : "",
                    ownerfirst: examData.ownerfirst,
                    ownerlast: examData.ownerlast,
                    ownermobile: examData.ownermobile,
                    owneremail: examData.owneremail,
                    owneraddress1: examData.owneraddress1,
                    ownercity: examData.ownercity,
                    ownerprovince: examData.ownerprovince,
                    ownerpostalcode: examData.ownerpostalcode,
                    petname: examData.petname,
                    petspecies: examData.petspecies,
                    petbreed: examData.petbreed,
                    petbreedother: examData.petbreedother,
                    petcolor: examData.petcolor,
                    petdob: (examData.petdob && (examData.petdob.length > 0)) ? (new Date(examData.petdob)).toLocaleDateString() : null,
                    petgender: examData.petgender,
                    petweight: examData.petweight,
                    petemployee: examData.petemployee,
                    petmicrochipref: examData.petmicrochipref,
                    petmicrochiptag: examData.petmicrochiptag,
                    petalert: examData.petalert,
                    cwagency: examData.cwagency,
                    cwname: examData.cwname,
                    cwemail: examData.cwemail,
                    vetname: examData.vetname,
                    vetphone: examData.vetphone,
                    vetaddress: examData.vetaddress,
                }}
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement style={{}}>
                        <fieldset
                            className={"k-form-fieldset"}
                        >
                            <GridLayout
                                gap={{ rows: 0, cols: 0 }}
                                cols={[{ width: "20%" }, { width: "20%" }, { width: "20%" }, { width: "20%" }, { width: "20%" }]}>
                                <GridLayoutItem row={1} col={1} colSpan={4}>
                                    <FieldWrapper>
                                        <Label className="k-form-label">Note</Label>
                                        <div className="k-form-field-wrap">
                                            <Field
                                                style={{ color: "red", fontSize:"larger" }}
                                                name={"petalert"}
                                                component={Input}
                                                disabled={true}
                                            />
                                        </div>
                                    </FieldWrapper>
                                </GridLayoutItem>
                                <GridLayoutItem row={1} col={5}>
                                    <FieldWrapper style={{ marginLeft: "16px" }}>
                                        <Label className="k-form-label">Veterinarian</Label>
                                        <div className="k-form-field-wrap">
                                            <Field
                                                name={"vetname"}
                                                component={Input}
                                                disabled={true}
                                            />
                                        </div>
                                    </FieldWrapper>
                                </GridLayoutItem>
                            </GridLayout>
                        </fieldset>
                        <fieldset
                            className={"k-form-fieldset"}
                        >
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">Status</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"status"}
                                            component={Input}
                                            disabled={true}
                                            style={{
                                                color: (examData.status ? (examData.status.startsWith("Closed") ? "blue" : "green") : "")
                                            }}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Submitted</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"submitted"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Agency</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"cwagency"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Case Worker</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"cwname"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">CW Email</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"cwemail"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                            </StackLayout>
                        </fieldset>
                        <fieldset
                            className={"k-form-fieldset"}
                        >
                            <legend className={"k-form-legend"}>Owner</legend>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">First Name</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownerfirst"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Last Name</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownerlast"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Mobile Phone</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownermobile"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Email</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"owneremail"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <div/>
                            </StackLayout>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">Address</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"owneraddress1"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">City</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownercity"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Province</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownerprovince"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Postal Code</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"ownerpostalcode"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <div />
                            </StackLayout>
                        </fieldset>
                        <fieldset
                            className={"k-form-fieldset"}
                        >
                            <legend className={"k-form-legend"}>Pet</legend>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">Name</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petname"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Species</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petspecies"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Breed</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petbreed"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Breed (Other)</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petbreedother"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Color</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petcolor"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                            </StackLayout>
                            <StackLayout gap={16}>
                                <FieldWrapper>
                                    <Label className="k-form-label">Birthdate</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petdob"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Gender</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petgender"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Weight (kg)</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petweight"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Employee Pet</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petemployee"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Label className="k-form-label">Microchip #</Label>
                                    <div className="k-form-field-wrap">
                                        <Field
                                            name={"petmicrochipref"}
                                            component={Input}
                                            disabled={true}
                                        />
                                    </div>
                                </FieldWrapper>
                            </StackLayout>
                        </fieldset>
                    </FormElement>
                )}
            />

            {!viewOnly && <div>
                <legend style={{fontWeight:"bold", fontSize:"14px", marginTop:"16px", color:"green"}}>PREVIOUS EXAMS</legend>
                < InlineExamsGrid
                            data={petData.exams}
                            examIdentifier={examData.id}
                    petTitle={examData.petname + " (" + examData.ownerfirst + " " + examData.ownerlast + ")"}
                    returnToLabel="Return to Exam"
                />
            </div>}
            </div>) : null}
        </React.Fragment>
    );
};
export default ExamViewClientPet;
