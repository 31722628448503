import * as React from "react";
import {
    DropDownButton,
    DropDownButtonItem,
    DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";

import { useAppDispatch, useAppSelector } from "../store/store";
import { logout } from "../store/auth-slice";
import { Navigate } from 'react-router-dom';
import { sunbrightApi } from '../store/sunbright-api';

const Login = (props: any) => {

    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth);

    const [authenticated, setAuthenticated] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.username && (auth.user.username.length > 0));
    const [showProfile, setShowProfile] = React.useState<boolean>(false);

    const itemClicked = (e: DropDownButtonItemClickEvent) => {
        if (e.item.text === "Account") {
            setShowProfile(true);
        }

        else if (e.item.text === "Logout") {
            dispatch(
                logout({
                    user: {},
                }));
            setAuthenticated(false);

            dispatch(sunbrightApi.util.resetApiState());
        }
    }

    return (
        <div>
            {(!authenticated) && (
                <Navigate to="/login" />
            )}
            {(authenticated) && (
                <DropDownButton
                    size="small"
                    themeColor="primary"
                    fillMode="solid"
                    rounded="full"
                    text={auth.user.firstName.charAt(0) + auth.user.lastName.charAt(0)}
                    onItemClick={itemClicked}
                >
                    <DropDownButtonItem text="Account" icon="user" />
                    <DropDownButtonItem text="Logout" icon="logout"/>
                </DropDownButton>
            )}
            {(showProfile &&
                <Dialog
                    title={auth.user.firstName + " " + auth.user.lastName}
                    onClose={(e) => setShowProfile(false)}
                >
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        {auth.user.role}<br />
                    </p>
                </Dialog>
            )}
        </div>
    );
};
export default Login;
