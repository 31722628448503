import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";

import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardImage } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../store/store";
import { login } from "../store/auth-slice";
import { Navigate } from 'react-router-dom';

import { post } from '../helpers/fetch-wrapper'

const Login = (props: any) => {

    const dispatch = useAppDispatch();
    const auth = useAppSelector((state) => state.auth);

    const [authenticated, setAuthenticated] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.username && (auth.user.username.length > 0));
    //const [authenticated, setAuthenticated] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string>("");


    const postResult = (result: any) => {
        if (result.message) {
            setAuthenticated(false);
            setErrorMessage(result.message);
        } else {
            dispatch(
                login({
                    user: result,
                }));
            setAuthenticated(true);
            setErrorMessage("");
        }
    };

    const handleSubmit = (dataItem: { [name: string]: any }) => {
        post("/users/authenticate", { username: dataItem.username, password: dataItem.password }, postResult);
    }

    return (
        <div>
            {(authenticated) && (
                <Navigate to="/" />
            )}
            {(!authenticated) && (
                <div
                    style={{ backgroundColor: "lightgray", width: "378px", height: "389px", margin: "auto", marginTop: "20px" }}
                >
                    <Card style={{ width: "278px", position: "relative", top: "50px", bottom: "50px", left: "50px" }}>
                        <CardImage
                            src="./CVO-Logo-Header-200.png"
                            style={{
                                margin: "auto",
                            }}
                        />
                        <CardBody>
                            <Form
                                onSubmit={handleSubmit}
                                render={(formRenderProps: FormRenderProps) => (
                                    <FormElement style={{ maxWidth: 650 }}>
                                        <fieldset
                                            className={"k-form-fieldset"}
                                            onFocus={() => { setErrorMessage("") }}
                                        >
                                            <FieldWrapper>
                                               <Label className="k-form-label">Username</Label>
                                               <div className="k-form-field-wrap">
                                                    <Field
                                                        className="sds-login"
                                                        name={"username"}
                                                        component={Input}
                                                    />
                                                </div>
                                            </FieldWrapper>

                                            <FieldWrapper>
                                               <Label className="k-form-label">Password</Label>
                                               <div className="k-form-field-wrap">
                                                    <Field
                                                        className="sds-login"
                                                        name={"password"}
                                                        type="password"
                                                        component={Input}
                                                    />
                                                </div>
                                            </FieldWrapper>

                                        </fieldset>

                                        <p style={{ color: "red", whiteSpace:"pre" }}>{errorMessage + " "}</p>
                                        <Button
                                            style={{ width: "95%", margin: "auto"}}
                                            themeColor={"primary"}
                                            type="submit"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            Login
                                        </Button>
                                    </FormElement>
                                )}
                            />
                        </CardBody>
                    </Card>
                </div>
            )}
        </div>
    );
};
export default Login;
