﻿import * as React from "react";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridSortChangeEvent,
    GridFilterChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { getter } from "@progress/kendo-react-common";

import {
    orderBy,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
} from "@progress/kendo-data-query";

import NavigateToLogin from "./NavigateToLogin";

import Pet from "./Pet";

import { useGetPetsQuery } from '../store/sunbright-api';

const cellWithCheck = (props: GridCellProps) => {
    const active = props.dataItem.active;

    const style = {
        color: "green",
    };
    return (
        <td style={style} data-grid-col-index={11}>
            {active === "1" ? <span className="k-icon k-font-icon k-i-check" /> : null}
        </td>
    );
};

const initialSort: Array<SortDescriptor> = [
    { field: "lastExam", dir: "desc" },
];

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [{ field: "PetName", operator: "contains", value: "" }],
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const PetsGrid = (props: any) => {
    const { data, error, isLoading } = useGetPetsQuery("test");

    const [gridHeight, setGridHeight] = React.useState<string>("400px");
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 100) + "px");
    }, [data]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState(initialFilter);

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };

    return (
        <React.Fragment>
            {(selectedUID.length === 0) ?
                (<div>
                    <div className="sds-gridHeader">Pets</div>
                    {error ? (<NavigateToLogin />) : isLoading ? (<Loader size="large" type="converging-spinner" />) : data ? (
                        <Grid
                            style={{ height: gridHeight }}
                            className="petGrid sds-selectable"
                            data={orderBy(filterBy(data.map((item: any) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)]
                            })), filter), sort)}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}
                            filterable={true}
                            filter={filter}
                            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                            resizable={true}
                            reorderable={true}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                mode: "single",
                            }}
                            onSelectionChange={handleSelectionChange}
                            size={"small"}
                        >
                            <GridColumn field="petName" title="Pet Name" filterable={true} filter="text" />
                            <GridColumn field="owner" title="Owner" filterable={true} filter="text" />
                            <GridColumn field="agencyGroup" title="Agency Group" filterable={false} />
                            <GridColumn field="exams" title="Exams" filterable={false} filter="text" />
                            <GridColumn field="species" title="Species" filterable={false} />
                            <GridColumn field="breed" title="Breed" filterable={false} />
                            <GridColumn field="breedOther" title="Breed-Other" filterable={false} />
                            <GridColumn field="gender" title="Gender" filterable={false} />
                            <GridColumn field="microchipTag" title="Microchip" filterable={false} />
                            <GridColumn field="microchipNumber" title="Microchip #" filterable={false} />
                            <GridColumn field="lastExam" title="Last Exam" filterable={false} format="{0:MMM d yyyy}" />
                            <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck} />
                        </Grid>) : null}
                </div>) : 
                (<Pet
                    petID={selectedUID}
                    returnLabel="Return to Pets"
                    onReturn={() => setSelectedUID("")}
                    hideExams={false }
                />
           )}
        </React.Fragment>
    );
}
export default PetsGrid;
