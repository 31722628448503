import * as React from "react";
import {
    AppBar,
    AppBarSection,
    AppBarSpacer,
    Avatar,
} from "@progress/kendo-react-layout";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";

import AvatarButton from "./AvatarButton";

function ApplicationBar() {
    return (
        <React.Fragment>
            <AppBar style={{ padding: "4px" }} >
                <AppBarSection >
                    <img src="CVO132.png" alt="CVO" style={{ height: "32px" }} />
                </AppBarSection>

                <AppBarSpacer style={{ width: 4 }} />

                <AppBarSection>
                    <span style={{ color: "#32a4d5" }}>
                        Community Veterinary Outreach Management System
                    </span>
                </AppBarSection>

                <AppBarSpacer />

                <AppBarSection className="actions">
                    <button className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
                        <BadgeContainer>
                            <span className="k-icon k-font-icon k-i-bell" />
                            <Badge
                                shape="dot"
                                themeColor="info"
                                size="small"
                                position="inside"
                            />
                        </BadgeContainer>
                    </button>
                </AppBarSection>

                <AppBarSection>
                    <span className="k-appbar-separator" />
                </AppBarSection>

                <AppBarSection>
                    <AvatarButton />
                </AppBarSection>
            </AppBar>
        </React.Fragment>
    );
}

export default ApplicationBar;
