﻿import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
    GridFilterCellProps,
} from "@progress/kendo-react-grid";

import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";

import { getter } from "@progress/kendo-react-common";

import {
    process,
    State,
    DataResult,
} from "@progress/kendo-data-query";

import NavigateToLogin from "./NavigateToLogin";

import Exam from "./Exam/Exam";

import { useGetExamsActiveQuery } from '../store/sunbright-api';
import { useAppSelector } from "../store/store";

import { IntlService } from "@progress/kendo-react-intl";
const DATE_FORMAT = "yyyy-MM-dd";
const intl = new IntlService("en");

const cellWithColor = (props: GridCellProps) => {
    const status = props.dataItem.Status;
    const color = (status === "Closed" || status === "Pending") ? "#999" : ((status === "Submitted") ? "#5cb85c" : "#5bc0de");      // gray : green : blue

    const style = {
        color: color,
        fontWeight: "900"
    };
    const field = props.field || "";
    return (
        <td style={style} data-grid-col-index={4}>
            {props.dataItem[field]}
        </td>
    );
};

interface CheckboxFilterCellProps extends GridFilterCellProps {
    onCheckboxChange: any;
}

export const CheckboxFilterCell = ({
    onCheckboxChange,
    ...props
}: CheckboxFilterCellProps) => {

    const onCheckboxClick = (event: CheckboxChangeEvent) => {
        props.onChange({
            value: event.value ? intl.formatDate(new Date(), DATE_FORMAT) : "",
            operator: event.value ? "eq" : "neq",
            syntheticEvent: event.syntheticEvent
        });
        onCheckboxChange(event.value);
    };

    return (
        <div className="k-filtercell">
            <Checkbox
                label="TODAY"
                onChange={(e) => onCheckboxClick(e)}
                value={props.value}
            />
        </div>
    );
};
//const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

const adminInitialDataState: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "appStart", operator: "isnotnull" },
            { field: "appEnd", operator: "isnotnull" },
        ],
    },
    sort: [{ field: "appStart", dir: "asc" }],
    group: [{ field: "appDate", dir: "desc" }]
};
const otherInitialDataState: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "appStart", operator: "isnotnull" },
            { field: "appEnd", operator: "isnotnull" },
            { field: "appDate", operator: "eq", value: intl.formatDate(new Date(), DATE_FORMAT)}
        ],
    },
    sort: [{ field: "appStart", dir: "asc" }],
    group: [{ field: "appDate", dir: "desc" }]
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ScheduleGrid = (props: any) => {

    const auth = useAppSelector((state) => state.auth);
    const [allDays, setAllDays] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        (auth.user.role.includes("Admin") || auth.user.role.includes("Case Worker")));

    const { data: dataActive, error: errorActive, isLoading: isLoadingActive } = useGetExamsActiveQuery("test");

    const [gridHeight, setGridHeight] = React.useState<string>("600px");
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 100) + "px");
    }, [dataActive]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [dataState, setDataState] = React.useState<State>(allDays ? adminInitialDataState : otherInitialDataState);
    const [dataResult, setDataResult] = React.useState<DataResult>(
        process([], dataState)
    );

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});
    const [selectedUID, setSelectedUID] = React.useState<string>("");

    React.useEffect(() => {
        if (dataActive) {
            setDataResult(process(dataActive.map((item: any) => ({
                ...item,
                appDate: item.appStart ? intl.formatDate(item.appStart, DATE_FORMAT) : "",
                [SELECTED_FIELD]: false
            })), dataState));
        }
    }, [dataActive, dataState]);

    const [todayOnly, setTodayOnly] = React.useState<boolean>(!allDays);

    const DateFilterCell: any = (props: GridFilterCellProps) => (
        <CheckboxFilterCell
            {...props}
            value={todayOnly}
            onCheckboxChange={setTodayOnly}
        />
    );

    const handleExpandChange = (event: GridExpandChangeEvent) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        setDataResult({ ...dataResult, data: [...dataResult.data] });
    };

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);

        setDataResult(process(dataActive.map((item: any) => ({
            ...item,
            appDate: item.appStart ? intl.formatDate(item.appStart, DATE_FORMAT) : "",
            [SELECTED_FIELD]: newState[idGetter(item)]
        })), dataState));

        setSelectedUID(Object.keys(newState)[0]);
    };

    return (
        <React.Fragment>
            {(selectedUID.length === 0) ?
                (<div>
                    <div className="sds-gridHeader">Appointments</div>
                    {errorActive ? (<NavigateToLogin />) : isLoadingActive ? <Loader size="large" type="converging-spinner" /> :
                        (dataActive && true) ? (
                            <Grid
                                style={{ height: gridHeight }}
                                className="schedGrid sds-selectable"
                                data={dataResult}
                                {...dataState}
                                onDataStateChange={(e: GridDataStateChangeEvent) => { setDataState(e.dataState); }}
                                sortable={false}
                                filterable={true}
                                resizable={true}
                                reorderable={true}
                                expandField="expanded"
                                onExpandChange={handleExpandChange}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    mode: "single",
                                }}
                                onSelectionChange={handleSelectionChange}
                                size={"small"}
                            >
                                <GridColumn field="appDate" title="Day" filterable={true} filterCell={DateFilterCell} />
                                <GridColumn field="appStart" title="Start" filterable={false} format="{0:hh:mm a}" />
                                <GridColumn field="appEnd" title="End" filterable={false} format="{0:hh:mm a}" />
                                <GridColumn field="petOwner" title="Pet (Owner)" filterable={true} filter="text" />
                                <GridColumn field="status" title="Status" filterable={false} cell={cellWithColor} />
                                <GridColumn field="services" title="Services" filterable={false} filter="text" />
                                <GridColumn field="veterinarian" title="Veterinarian" filterable={false} />
                                <GridColumn field="id" title="ID" filterable={false} />
                            </Grid>) : null}
                </div>) :
                (< Exam
                    examID={selectedUID}
                    returnLabel="Return to Appointments"
                    viewOnly={ false }
                    onReturn={() => setSelectedUID("")}
                />)}
       </React.Fragment>
    );
}
export default ScheduleGrid;
