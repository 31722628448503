﻿import * as React from "react";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridSortChangeEvent,
    GridFilterChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
    GridProps
} from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import {
    orderBy,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { Loader } from "@progress/kendo-react-indicators";

import Exam from './Exam/Exam'
import NavigateToLogin from "./NavigateToLogin";

import { useGetFollowUpExamsQuery } from '../store/sunbright-api';

export interface FollowupExamsGridProps extends GridProps {
    eventID: number;
}

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: []
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const FollupExamsGrid = ({
    eventID,
    ...gridProps
}: FollowupExamsGridProps) => {

    const { data: followupExams, error: error, isLoading: loading } = useGetFollowUpExamsQuery(eventID);

    const [selectedUID, setSelectedUID] = React.useState<string>("");
    const [selectedPet, setSelectedPet] = React.useState<string>("");

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const [showExam, setShowExam] = React.useState<boolean>(false);

    const [filter, setFilter] = React.useState(initialFilter);

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);
        setSelectedUID(Object.keys(newState)[0]);

        if (e && e.dataItems && (e.dataItems.length > 0)) {
            setSelectedPet(e.dataItems[0].pet)
        }

        setShowExam(true);
    };

    const grid = (
        <div>
            {error ? (<NavigateToLogin />) : loading ? <Loader size="large" type="converging-spinner" /> : followupExams ? (
                <Grid
                    className="serviceRequestedGrid"
                    sortable={false}
                    filterable={true}
                    filter={filter}
                    onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                    resizable={true}
                    reorderable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        mode: "single",
                    }}
                    size={"small"}
                    onSelectionChange={(e) => handleSelectionChange(e)}
                    data={filterBy(followupExams.map((item: any) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                    })), filter)}
                >
                    <GridColumn field="services" title="Services Requested" filterable={true} filter="text" />
                    <GridColumn field="client" title="Client" filterable={true} filter="text" />
                    <GridColumn field="pet" title="Pet" filterable={true} filter="text" />
                    <GridColumn field="caseWorker" title="Case Worker" filterable={false} />
                    <GridColumn field="id" title="ID" filterable={false} />
                </Grid>) : null}
        </div>
    );
    const pdfGrid = (
        <Grid
            className="serviceRequestedGrid"
            sortable={false}
            filterable={false}
            resizable={false}
            reorderable={false}
            selectable={{
                enabled: false,
            }}
            size={"small"}
            onSelectionChange={(e) => handleSelectionChange(e)}
            data={followupExams}
        >
            <GridColumn field="services" title="Services Requested" />
            <GridColumn field="client" title="Client" />
            <GridColumn field="pet" title="Pet" />
            <GridColumn field="caseWorker" title="Case Worker" />
            <GridColumn field="id" title="ID" filterable={false} />
        </Grid>
    );

    let gridPDFExport: GridPDFExport | null;
    const exportPDF = () => {
        if (gridPDFExport !== null) {
            gridPDFExport.save(followupExams ? filterBy(followupExams.map((item: any) => ({
                ...item
            })), filter) : undefined);
        }
    };

    return (
        <React.Fragment>
            {!showExam ?
                (<div>
                    <div style={{ position: "fixed", top: "124px", right: "34px", zIndex: "99" }}>
                        <ButtonGroup>
                            <Button
                                imageUrl="file-pdf.svg"
                                style={{ borderLeftColor: "white", borderLeftWidth: "1px" }}
                                disabled={false}
                                onClick={() => exportPDF()}
                                themeColor={"primary"}>
                                PDF
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div>
                        { grid }
                        <GridPDFExport ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                            { pdfGrid }
                        </GridPDFExport>
                    </div>
                </div>) :
                (<Dialog
                    title={selectedPet}
                    contentStyle={{ width: "1170px" }}
                    onClose={(e) => setShowExam(false)}
                >
                    <Exam
                        examID={selectedUID}
                        viewOnly={true}
                        returnLabel={""}
                        onReturn={() => setSelectedUID("")}
                    />
                </Dialog>)
            }
        </React.Fragment>
    );
}
export default FollupExamsGrid;
