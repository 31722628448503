
function http(path: string, requestOptions: any, callback: any) {
	const request = new Request(path, requestOptions);
	fetch(request).then(response => response.json()).then(function (response: any) {
		callback(response);
	});
}

export const post = (
	service: string,
	body:any,
	callback: any
) => {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(body),
	};
	http("https://sunbrightvet.com" + service, requestOptions, callback)
}
