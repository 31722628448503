﻿import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";

import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";

import InlineExamsGrid from './Exam/InlineExamsGrid'
import InlinePersonsGrid from './InlinePersonsGrid'
import InlinePetsGrid from './InlinePetsGrid'

import AgencyNew from './AgencyNew'
import NavigateToLogin from "./NavigateToLogin";

import { useAppSelector } from "../store/store";
import { useGetAgencyQuery } from '../store/sunbright-api';

export interface AgencyViewProps {
    agencyID: string;
    viewOnly: boolean;
    returnLabel: string;
    onReturn?: any;
}

const AgencyView = ({
    agencyID,
    viewOnly,
    returnLabel,
    onReturn,
}: AgencyViewProps) => {
    const auth = useAppSelector((state) => state.auth);
    const [canEdit, setCanEdit] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        auth.user.role.includes("Admin") && !viewOnly);

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const { data, error, isLoading } = useGetAgencyQuery(agencyID);

    const [agencyData, setAgencyData] = React.useState<any>(null);

    const [formHeight, setFormHeight] = React.useState<string>("600px");

    const handleResize = () => {
        setFormHeight((window.innerHeight - 110) + "px");
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    React.useEffect(() => {
        setFormHeight((window.innerHeight - 110) + "px");

        if (data) {
            setAgencyData(data);
        }
    }, [data]);

    const editReturned = (refresh: boolean) => {
        setIsEdit(false);
        if (refresh) {
            setAgencyData(null);
        }
    }

    return (
        <React.Fragment>
            <div className="sds-window">
                {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : agencyData ? (
                    <div>
                        <Toolbar>
                            <ButtonGroup>
                                    <Button
                                        icon="undo"
                                        onClick={(e) => onReturn() }
                                    >
                                        {returnLabel}
                                    </Button>
                            </ButtonGroup>
                        </Toolbar>
                        {canEdit && (
                            <div style={{ position: "fixed", top: "64px", right: "34px", zIndex: "99" }}>
                                <ButtonGroup>
                                    {canEdit && <Button
                                        icon="edit"
                                        themeColor={"primary"}
                                        onClick={() => setIsEdit(true)}>
                                        Edit
                                    </Button>}
                                    <Button
                                        icon="print"
                                        style={{ borderLeftColor: "white", borderLeftWidth: "1px" }}
                                        disabled={false}
                                        themeColor={"primary"}>
                                        Print
                                    </Button>
                                </ButtonGroup>
                            </div>)}
                        {isEdit ?
                            <Dialog contentStyle={{ width: "1170px" }}>
                                <AgencyNew
                                    agencyData={agencyData}
                                    onReturn={(refresh: boolean) => editReturned(refresh)}
                                />
                            </Dialog>
                            :
                            <Form
                                initialValues={{
                                    name: agencyData.name,
                                    mobile: agencyData.mobilePhone,
                                    local: agencyData.localPhone,
                                    email: agencyData.email,
                                    address1: agencyData.address1,
                                    city: agencyData.city,
                                    province: agencyData.province,
                                    postal: agencyData.postalCode,
                                    type: agencyData.type,
                                    emailReport: (agencyData.emailReport === "0") ? "No" : "Yes",
                                    active: (agencyData.active === "0") ? "No" : "Yes",
                                    username: agencyData.username,
                                }}
                                render={(formRenderProps: FormRenderProps) => (
                                    <div style={{ height: formHeight, overflow: "auto" }}>
                                        <FormElement style={{ margin: "16px" }}>
                                            <fieldset
                                                className={"k-form-fieldset"}
                                            >
                                                <StackLayout gap={16}>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Name</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"name"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Phone</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"mobile"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Email</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"email"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={16}>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Address</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"address1"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">City</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"city"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Province</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"province"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                    <FieldWrapper>
                                                        <Label className="k-form-label">Postal Code</Label>
                                                        <div className="k-form-field-wrap">
                                                            <Field
                                                                name={"postal"}
                                                                component={Input}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FieldWrapper>
                                                </StackLayout>
                                            </fieldset>
                                        </FormElement>
                                        <legend className={"k-form-legend"}>Case Workers</legend>
                                        <InlinePersonsGrid
                                            style={{ maxHeight: "200px", margin: "16px 32px 32px 32px" }}
                                            data={agencyData.persons}
                                            role="Case Worker"
                                            returnToLabel="Return to Agency"
                                        />
                                        <legend className={"k-form-legend"}>Exams</legend>
                                        <InlineExamsGrid
                                            style={{ maxHeight: "200px", margin: "16px 32px 32px 32px" }}
                                            data={agencyData.exams}
                                            petTitle={agencyData.name}
                                            returnToLabel="Return to Agency"
                                        />
                                        <legend className={"k-form-legend"}>Pets</legend>
                                        <InlinePetsGrid
                                            style={{ maxHeight: "200px", margin: "16px 32px 32px 32px" }}
                                            data={agencyData.pets}
                                            returnToLabel="Return to Agency"
                                        />
                                    </div>
                                )}
                            />
                        }
                    </div>)
                    :
                    null
                }
                </div>
       </React.Fragment>
    );
};
export default AgencyView;
