﻿import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Editor } from "@progress/kendo-react-editor";
import {
    Grid,
    GridColumn,
    GridCellProps,
} from "@progress/kendo-react-grid";
import {
    Button, ButtonGroup, Toolbar} from "@progress/kendo-react-buttons";
import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from "@progress/kendo-react-layout";

import { Label } from "@progress/kendo-react-labels";
import { TextBox, TextArea } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";

import NavigateToLogin from "./NavigateToLogin";

import { useAppSelector } from "../store/store";
import { useGetFollowUpQuery } from '../store/sunbright-api';

import EventNew from './EventNew'
import FollowupExamsGrid from './FollowupExamsGrid'

const info = [
    {
        t: "<span style=\"color:blue\">Clients/Pets</span>",
        text: "Clients/Pets:",
        h: "40px",
        gap: "0px"
    },
    {
        t: "<span style=\"color:blue\">One Health Services</span>",
        text: "One Health Services:",
        h: "40px",
        gap: "0px"
    },
    {
        t: "<span style=\"color:blue\">Hours</span>",
        text: "Hours:",
        h: "40px",
        gap: "0px"
    },
]
export interface EventViewProps {
    eventID: number;
    onReturn?: any;
}

const toReactComponent = (html: any) => <span dangerouslySetInnerHTML={{ __html: html }} />

const cellWithHtml = (props: GridCellProps) => {
    const fieldVal = props.field ? props.dataItem[props.field] : null;

    const color = (fieldVal && props.dataItem.alert) ? (fieldVal.includes(props.dataItem.alert) ? "red" : "green") : "";
    const style = { color: color };

    let displayVal = toReactComponent(fieldVal);

    return (
        <td role="gridcell" style={style}>
            {displayVal}
        </td>
    );
};

const EventView = ({
    eventID,
    onReturn
}: EventViewProps) => {
    const auth = useAppSelector((state) => state.auth);
    const [canEdit, setCanEdit] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        auth.user.role.includes("Admin"));

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const { data: followup, error: followupError, isLoading: followupLoading } = useGetFollowUpQuery(eventID);

    const [followUpData, setFollowUpData] = React.useState<any>(null);

    const [selectedTab, setSelectedTab] = React.useState<number>(0);
    const followupTabStripRef = React.useRef<any>(null);
    const [tabStripHeight, setTabStripHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setTabStripHeight((window.innerHeight - 120) + "px");
    }, [followup]);

    const handleResize = () => {
        setTabStripHeight((window.innerHeight - 120) + "px");
    };

    const handleSelectTab = (e: TabStripSelectEventArguments) => {
        setSelectedTab(e.selected);
        if (followupTabStripRef && followupTabStripRef.current && followupTabStripRef.current._element && followupTabStripRef.current._element.children)
            followupTabStripRef.current._element.children[1].scrollTop = 0;
    };

    React.useEffect(() => {
        if (followup) {
            setFollowUpData(followup);
        }
    }, [followup]);

    const editReturned = (refresh: boolean) => {
        setIsEdit(false);
        if (refresh) {
            setFollowUpData(null);
        }
    }

    return (
        <React.Fragment>
            {followupError ? (<NavigateToLogin />) : followupLoading ? (<Loader size="large" type="converging-spinner" />) : followUpData ?
                (<div>
                    <Toolbar>
                        <ButtonGroup>
                            <Button
                                className="sds-buttoninbar"
                                icon="undo"
                                onClick={(e) => onReturn()}
                            >
                                Return to Follow Up
                            </Button>
                        </ButtonGroup>
                    </Toolbar>
                    <TabStrip
                        ref={followupTabStripRef}
                        selected={selectedTab}
                        onSelect={handleSelectTab}
                        keepTabsMounted={false}
                        animation={true}
                        style={{ paddingTop: "4px", height: tabStripHeight }}
                        className={"sds-window"}>
                        <TabStripTab title="Summary">

                        {canEdit && (
                            <div style={{ position: "fixed", top: "64px", right: "34px", zIndex: "99" }}>
                                <ButtonGroup>
                                    {canEdit &&
                                        <Button
                                            icon="edit"
                                            themeColor={"primary"}
                                            onClick={() => setIsEdit(true)}>
                                            Edit
                                        </Button>
                                    }
                                </ButtonGroup>
                            </div>)}
                        {isEdit ?
                            <Dialog contentStyle={{ width: "1170px" }}>
                                <EventNew
                                    followUpData={followUpData }
                                    onReturn={(refresh: boolean) => editReturned(refresh)}
                                />
                            </Dialog>
                            :
                            <div>
                                <Form
                                    render={(formRenderProps: FormRenderProps) => (
                                        <FormElement style={{}}>
                                            <fieldset className={"k-form-fieldset"} >
                                                {followUpData.fields.map((field: any) => {
                                                    return ((field.t === "info") ?
                                                        (<div className="k-form-field-wrap" style={{ marginTop: info[field.i - 1].gap }}>
                                                            <Editor style={{ backgroundColor: "aliceblue" }}
                                                                contentStyle={{ height: field.i ? info[field.i - 1].h : "", overflowX: "hidden" }}
                                                                defaultContent={field.i ? info[field.i - 1].t : ""}
                                                                tools={[]}
                                                            />
                                                        </div>)
                                                        :
                                                        ((field.t === "finding") ?
                                                            (<FieldWrapper>
                                                                <div className="k-form-field-wrap" style={{ backgroundColor: "lightblue" }}>
                                                                    <Label className="k-form-label">{field.result.q}</Label>
                                                                    <Field
                                                                        name={"A" + field.result.findingID}
                                                                        defaultValue={field.result.a + " " + field.result.n}
                                                                        style={{
                                                                            fontWeight: "bold",
                                                                        }}
                                                                        disabled={!field.result.n.includes('\n')}
                                                                        readOnly={true}
                                                                        component={(field.result.a.includes('\n') || (field.result.n.includes('\n'))) ? TextArea : TextBox}
                                                                    />
                                                                </div>
                                                            </FieldWrapper>) :
                                                            (<Grid
                                                                data={field.results}
                                                                style={{ fontWeight: "bold" }}
                                                                className={"sds-no-header sds-no-yscroll"}
                                                            >
                                                                <GridColumn field="q" width={"540px"} />
                                                                <GridColumn field="a" cell={cellWithHtml} />
                                                            </Grid>)
                                                        )
                                                    )
                                                })}
                                            </fieldset>
                                        </FormElement>
                                    )}
                                />
                            </div>
                            }
                        </TabStripTab>
                        <TabStripTab title="Services Requested">
                            <FollowupExamsGrid eventID={eventID} />
                        </TabStripTab>
                    </TabStrip>
                </div>) : null}
        </React.Fragment>
    );
};
export default EventView;
