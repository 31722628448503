import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
} from "@progress/kendo-react-buttons";

import { FormInput, FormDropDownList, FormCheckbox } from "./FormComponents";
import { requiredValidator, emailValidator, phoneValidator, userNameValidator, passwordValidator } from "./validators";

import { useGetAgencyNamesQuery } from '../store/sunbright-api';
import { useLazyGetCwNamesQuery } from '../store/sunbright-api';
import { useNewPersonMutation } from '../store/sunbright-api';

import { useAppSelector } from "../store/store";


export interface PersonNewProps {
    role: string;
    personData?: any;
    onReturn: any;
}
const PersonNew = ({
    role,
    personData,
    onReturn,
}: PersonNewProps) => {

    const auth = useAppSelector((state: any) => state.auth);
    const personID = (auth && (auth.user !== undefined) && (auth.user.id !== undefined)) ? auth.user.id : -1;

    const { data: agencynames, error: agencynamesError, isLoading: agencynamesLoading } = useGetAgencyNamesQuery(personID);
    const [getCwNames, { data: cwnames, error: cwnamesError, isLoading: cwnamesLoading }] = useLazyGetCwNamesQuery();

    const [newPerson, response] = useNewPersonMutation();

    const PersonNewForm = React.useRef<any>(null);

    React.useEffect(() => {
        if (agencynames && personData && personData.agencynamecombo && (personData.agencynamecombo.uid > 0)) {
            getCwNames(personData.agencynamecombo.uid);
        }
    }, [agencynames]);

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const handleAgencyChange = (e: DropDownListChangeEvent) => {
        getCwNames(e.value.uid);
        PersonNewForm.current.onChange("cwnamecombo", { value: null });
   }

    const submitForm = (event: FormSubmitClickEvent) => {

        const { isValid } = event;

        if (isValid) {
            const postExam = PersonNewForm.current.values;

            postExam.role = role;
            postExam.uid = personData ? personData.uid : 0;

            newPerson(postExam)
                .unwrap()
                .then((payload) => { onReturn(true) })
                .catch((error) => {
                    setErrorMessage("Username already exists.")
                });
        }
    }

    return (
        <React.Fragment>
            <div
                style={{ overflow: "auto", marginLeft: "16px" }}
                className={"sds-bold"}
            >
                <Form
                    ref={PersonNewForm}
                    initialValues={personData}
                    onSubmitClick={submitForm}
                    render={(formRenderProps: FormRenderProps) => (
                        <div>
                            <Toolbar>
                                <Button
                                    icon="upload"
                                    onClick={formRenderProps.onSubmit}
                                >
                                    Submit
                                </Button>
                                <Button
                                    icon="cancel"
                                    onClick={() => (onReturn ? onReturn(false) : null)}
                                >
                                    Cancel
                                </Button>
                                <div style={{ fontSize: "large", fontWeight: "bold", color:"darkblue", paddingLeft: "64px" }}>
                                    {role}
                                </div>
                            </Toolbar>
                            <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{overflow: "auto", marginLeft: "16px", marginRight: "32px" }}>
                                    <div className={"sds-questionnaire"}>
                                        <fieldset
                                            className={"k-form-fieldset"}
                                            onFocus={() => { setErrorMessage("") }}
                                       >
                                            {((role === "Case Worker") || (role === "Client")) &&
                                                <StackLayout gap={16}
                                                    style={{ marginTop: "-24px" }}
                                                >
                                                    <Field
                                                        label={"Agency*"}
                                                        name={"agencynamecombo"}
                                                        component={FormDropDownList}
                                                        data={agencynames}
                                                        onChange={handleAgencyChange}
                                                        textField={"name"}
                                                        validator={requiredValidator}
                                                    />
                                                    {(role === "Client") &&
                                                        <Field
                                                            label={"Case Worker*"}
                                                            name={"cwnamecombo"}
                                                            component={FormDropDownList}
                                                            data={cwnames}
                                                            textField={"name"}
                                                            validator={requiredValidator}
                                                        />
                                                    }
                                                    <div/><div/>
                                                    {(role === "Case Worker") && <div />}
                                               </StackLayout>
                                            }

                                            <StackLayout gap={16}>
                                                {(role === "Veterinarian") &&
                                                    <Field
                                                        label={"Title"}
                                                        name={"titleName"}
                                                        component={FormInput}
                                                        validator={requiredValidator}
                                                    />
                                                }
                                                <Field
                                                    label={"First Name*"}
                                                    name={"firstName"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Last Name*"}
                                                    name={"lastName"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                                {(role === "Veterinarian") &&
                                                    <Field
                                                        label={"Credentials*"}
                                                        name={"credentials"}
                                                        component={FormInput}
                                                        validator={requiredValidator}
                                                    />
                                                }
                                                {((role === "Case Worker") || (role === "Client")) &&
                                                    <Field
                                                        label={"Phone"}
                                                        name={"mobilePhone"}
                                                        component={FormInput}
                                                        validator={phoneValidator}
                                                    />
                                                }
                                                <Field
                                                    label={(role !== "Client") ? "Email*" : "Email"}
                                                    name={"email"}
                                                    component={FormInput}
                                                    validator={(role !== "Client") ? [emailValidator, requiredValidator] : emailValidator}
                                                />
                                                {(role === "Admin") &&
                                                    <div/>
                                                }
                                            </StackLayout>
                                            {((role === "Case Worker") || (role === "Client")) &&
                                                <StackLayout gap={16}>
                                                    <Field
                                                        label="Address"
                                                        name={"address1"}
                                                        component={FormInput}
                                                    />
                                                    <Field
                                                        label="City"
                                                        name={"city"}
                                                        component={FormInput}
                                                    />
                                                    <Field
                                                        label="Province"
                                                        name={"province"}
                                                        component={FormInput}
                                                    />
                                                    <Field
                                                        label="Postal Code"
                                                        name={"postalCode"}
                                                        className={"sds-uppercase"}
                                                        component={FormInput}
                                                    />
                                                </StackLayout>
                                            }
                                            {(role !== "Client") && (
                                                <StackLayout gap={16}>
                                                    <Field
                                                        label={"Username*"}
                                                        name={"username"}
                                                        component={FormInput}
                                                        validator={userNameValidator}
                                                        autoComplete={"new-password"}
                                                    />
                                                    {(!personData) &&
                                                        <Field
                                                            label={"Password*"}
                                                            name={"LL"}
                                                            type="password"
                                                            component={FormInput}
                                                            validator={passwordValidator}
                                                            autoComplete={"new-password"}
                                                       />
                                                    }
                                                    {(role === "Veterinarian") &&
                                                        <div style={{ marginTop: "20px"}}>
                                                           <Field
                                                                style={{ borderColor: "black" }}
                                                                label={"Administrator"}
                                                                name={"administrator"}
                                                                component={FormCheckbox}
                                                            />
                                                        </div>
                                                    }
                                                    <div style={{ marginTop: "20px" }}>
                                                        <Field
                                                            style={{ borderColor: "black" }}
                                                            label={"Active"}
                                                            name={"active"}
                                                            defaultValue={true }
                                                            component={FormCheckbox}
                                                        />
                                                    </div>
                                                    {(personData) &&
                                                        <div></div>
                                                    }
                                                    {(role !== "Intake") &&
                                                        <div></div>
                                                    }
                                                </StackLayout>
                                            )}
                                        </fieldset>
                                    </div>
                                </FormElement>
                                <p style={{ color: "red", whiteSpace: "pre" }}>{errorMessage + " "}</p>
                                </div>
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
      );
}
export default PersonNew;