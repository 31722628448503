import * as React from "react";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";
import {
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SvgIcon } from "@progress/kendo-react-common";
import { printIcon } from "@progress/kendo-svg-icons";

import ExamNewClientPet from "./ExamNewClientPet";
import ExamNewServices from "./ExamNewServices";
import ExamNewEligibility from "./ExamNewEligibility";
import ExamNewWaivers from "./ExamNewWaivers";
import ExamNewQuestionnaire from "./ExamNewQuestionnaire";
import ExamNewRecord from "./ExamNewRecord";
import ExamNewVaccinations from "./ExamNewVaccinations";
import ExamNewSchedule from "./ExamNewSchedule";
import ExamNewComments from "./ExamNewComments";

import { useEditExamMutation } from '../../store/sunbright-api';

export interface ExamEditProps {
    examID: string;
    examData: any;
    category: string;
    fields: any,
    onReturn?: any;
}

const ExamEdit = ({
    examID,
    examData,
    category,
    fields,
    onReturn,
}: ExamEditProps) => {

    const [editExam, response] = useEditExamMutation();

    const ExamEditForm = React.useRef<any>(null);

    const [formHeight, setFormHeight] = React.useState<string>("100px");

    const [isError, setIsError] = React.useState<boolean>(false);

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        setFormHeight((window.innerHeight - 90) + "px");
    }, []);

    const handleResize = () => {
        setFormHeight((window.innerHeight - 90) + "px");
    };

    const examValues: any = (examData: any) => {
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

        return {
            status: examData.status,
            opened: examData.opened ? examData.opened.toLocaleDateString("en-US", dateOptions).substring(5) : "",
            submitted: examData.submitted ? examData.submitted.toLocaleDateString("en-US", dateOptions).substring(5) : "",
            ownerfirst: examData.ownerfirst,
            ownerlast: examData.ownerlast,
            ownermobile: examData.ownermobile,
            owneremail: examData.owneremail,
            owneraddress1: examData.owneraddress1,
            ownercity: examData.ownercity,
            ownerprovince: examData.ownerprovince,
            ownerpostalcode: examData.ownerpostalcode,
            petname: examData.petname,
            petspecies: examData.petspecies,
            petbreed: examData.petbreed,
            petbreedother: examData.petbreedother,
            petcolor: examData.petcolor,
            petdob: (examData.petdob && (examData.petdob.length > 0)) ? new Date(examData.petdob) : null,
            petgender: examData.petgender,
            petweight: examData.petweight,
            petemployee: examData.petemployee,
            petmicrochipref: examData.petmicrochipref,
            petmicrochiptag: examData.petmicrochiptag,
            petalert: examData.petalert,
            cwagency: examData.cwagency,
            cwname: examData.cwname,
            cwemail: examData.cwemail,
            vetname: examData.vetname,
            vetphone: examData.vetphone,
            vetaddress: examData.vetaddress,
        }
    }

    const findingValues: any = (fields: any) => {
        var defaultValues: any = {};

        const dateFields: number[] = [149, 200, 313, 317, 321, 333, 334, 336, 343, 347];
        const multiFields: number[] = [170, 171, 175, 182, 183,
            199, 202, 206, 207, 209, 215, 218, 219, 231, 233, 236, 237, 324, 382];

        var findings = fields.reduce(function (result:any, field:any) {
            var answerKey: string = "";
            var answerKey2: string = "";

            if (field.t === "finding") {
                answerKey = "A" + field.result.findingID;

                if ((field.result.a.length === 0) && (defaultValues[answerKey])) {
                    field.result.a = defaultValues[answerKey];
                }

                if (multiFields.includes(field.result.findingID)) {
                    result[answerKey] = field.result.a.split(" | ");
                }
                else if ((field.result.findingID === 311) || (field.result.findingID === 317)) {
                    result[answerKey] = (field.result.a === "Yes");
                }
                else if (dateFields.includes(field.result.findingID)) {
                    result[answerKey] = (field.result.a.length > 0) ? new Date(field.result.a) : "";
                }
                else {
                    result[answerKey] = field.result.a;
                }
                result["N" + field.result.findingID] = field.result.n;
            }
            else if (field.t === "grid") {
                for (field of field.results) {
                    answerKey = "A" + field.findingID;

                    if ((field.a.length === 0) && (defaultValues[answerKey])) {
                        field.a = defaultValues[answerKey];
                    }

                    if (dateFields.includes(field.findingID)) {
                       result[answerKey] = (field.a.length > 0) ? new Date(field.a) : "";
                    } else if (multiFields.includes(field.findingID)) {
                       result[answerKey] = field.a.split(" | ");
                    }
                    else {
                       result[answerKey] = field.a;
                    }
                    result["N" + field.findingID] = field.n;
                }
            }
            else if (field.t === "grid2") {

                for (field of field.results) {
                    answerKey = "A" + field.findingID;
                    answerKey2 = "A" + (field.findingID + 1);
                    result[answerKey] = (field.a1 === "Yes");
                    result[answerKey2] = (field.a2 === "Yes");
                }
            }

            return result;
        }, {})

        if (Object.keys(findings).length === 0) {
            findings["A203"] = "Vaccination and Wellness exam";
            findings["A204"] = "No";
            findings["A208"] = "No health concerns identified by client";
            findings["A209"] = ["BAR (bright, alert, responsive)"];
            findings["A210"] = "Normal";
            findings["A211"] = "Normal";
            findings["A212"] = "Normal";
            findings["A213"] = "Normal";
            findings["A214"] = "Normal";
            findings["A215"] = ["Normal"];
            findings["A216"] = "No";
            findings["A217"] = "No";
            findings["A218"] = ["None"];
            findings["A219"] = ["None"];
            findings["A220"] = "2";
            findings["A225"] = (examData.petSpecies === "Cat") ? "5" : "4-5";
            findings["A229"] = "Less than 2";
            findings["A230"] = "Normal";
            findings["A231"] = ["Normal"];
            findings["A233"] = ["Normal"];
            findings["A234"] = "0";
            findings["A235"] = "No";
            findings["A236"] = ["Normal"];
            findings["A237"] = ["Normal"];
            findings["A238"] = "Normal";
            findings["A239"] = "Normal";
            findings["A240"] = "Normal";
            findings["A241"] = "No";
            findings["A242"] = "Normal";
            findings["A243"] = "Normal";
            findings["A244"] = "Normal";
            findings["A245"] = "Normal";
            findings["A246"] = "Healthy, OK to vaccinate";
            findings["A247"] = "No problems documented";
            findings["A248"] = "Drug Name: \rDrug concentration (mg): \rDose administered (in mg, mL, or tube): \rRoute of Administration (oral, topical, injection SC, injection IM):";
            findings["A249"] = "Drug Name: \rDrug concentration (mg): \rDirections for owner, including route of administration: \rNumber dispensed (in mL, tablets, or tubes): \rDuration of therapy (days):";
            findings["A250"] = "Recheck at a future Outreach clinic";
            findings["A255"] = true;
            findings["A257"] = true;
            findings["A259"] = true;
            findings["A261"] = true;
            findings["A263"] = true;
            findings["A379"] = "No";
       }

        findings["A310"] = "Private";      // Comments (default)
        findings["N310-prev"] = findings["N310"];
        findings["N310"] = "";            // Show but do not change previous comments

        return findings;
    };

    const [initialValues, setInitialValues] = React.useState<any>((category === "Info") ? examValues(examData) : findingValues(fields));

    const postForm = (saveOnly: boolean) => {
        const postExam = ExamEditForm.current.values;
        postExam.Category = category;
        postExam.ExamID = examID;
        postExam.OwnerID = examData.ownerid;
        postExam.PetID = examData.petid;
        if (saveOnly) {
            postExam.Action = "Save";
        }

        editExam(postExam)
            .unwrap()
            .then((payload) => { })
            .catch((error) => {
                console.log(error)
            });
        onReturn(true);
    }

    const saveForm = () => {
        postForm(true);
    }


    const submitForm = (event: FormSubmitClickEvent) => {

        const { isValid } = event;

        if (isValid) {
            postForm(false);
        }
        else {
            setIsError(true)
        }
    }

    return (
        <React.Fragment>

            {(isError) &&
                <Dialog
                    title={<div><span className="k-icon k-font-icon k-i-error" style={{ color: "red" }} ></span>&nbsp;Missing Fields</div>}
                    onClose={(e) => setIsError(false)}>
                    <p style={{ margin: "25px", textAlign: "left" }}>
                        Not all of the required fields have been entered.<br /><br />Please review the entire form.
                    </p>
                </Dialog>
            }

           <div
                style={{ overflow: "auto", marginLeft: "16px" }}
                className={"sds-bold"}
            >
                <Form
                    ref={ExamEditForm}
                    initialValues = { initialValues }
                    onSubmitClick={submitForm}
                    render={(formRenderProps: FormRenderProps) => (
                        <div>
                             <Toolbar>
                                {((true) && <Button
                                    icon="upload"
                                    onClick={formRenderProps.onSubmit}
                                >
                                    Submit
                                </Button>)}
                                <ButtonGroup>
                                    {((category === "ExamRecord") && <Button
                                        icon="save"
                                        onClick={saveForm}
                                    >
                                        Save
                                    </Button>)}
                                    <Button
                                        icon="cancel"
                                        onClick={(e) => onReturn(false)}
                                    >
                                        Cancel
                                    </Button>
                                </ButtonGroup>
                            </Toolbar>
                           <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{ height: formHeight, overflow: "auto", marginLeft: "16px", marginRight: "32px" }}>
                                    {(category === "Info") && <ExamNewClientPet newExam={false} form={ExamEditForm} />}
                                    {(category === "Schedule") && <ExamNewSchedule formRenderProps={formRenderProps} />}
                                    {(category === "Services") && <ExamNewServices />}
                                    {(category === "Eligibility") && <ExamNewEligibility />}
                                    {(category === "Waivers") && <ExamNewWaivers clientName="" petName=""/>}
                                    {(category === "Questionnaire") && <ExamNewQuestionnaire />}
                                    {(category === "ExamRecord") && <ExamNewRecord species={examData.petspecies} formRenderProps={formRenderProps} />}
                                    {(category === "Vaccinations") && <ExamNewVaccinations
                                        species={examData.petspecies}
                                        vac2={initialValues.A319}
                                        vac3={initialValues.A341}
                                        vac4={initialValues.A345}
                                    />}
                                    {(category === "Comments") && <ExamNewComments newExam={false} examID={examID} />}
                                </FormElement>
                            </div>
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
    );
};
export default ExamEdit;
