import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthPayload {
    user?: any  | null,
}
type AuthState = AuthPayload;

function getInitialUser (): any {

    let localUser = {};

    let user = localStorage.getItem('user');
    if (user != null) {
        localUser = JSON.parse(user);
    }

    return localUser;
};

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: getInitialUser(),
    } as AuthState,
    reducers: {
        logout(
            state,
            { payload }: PayloadAction<AuthPayload>
        ) {
            state.user = {};
            localStorage.removeItem('user');
        },
        login(
            state,
            { payload }: PayloadAction<AuthPayload>
        ) {
            if (payload.user) {
                state.user = payload.user;
                localStorage.setItem('user', JSON.stringify(payload.user));
           }
        },
   },
});

export const { logout, login } = authSlice.actions;
export default authSlice.reducer;
