import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";

import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from "@progress/kendo-react-layout";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridDataStateChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";

import {
    process,
    State,
    DataResult,
} from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";

import NavigateToLogin from "./NavigateToLogin";

import PersonView from './PersonView'
import PersonNew from './PersonNew'

import { useGetPersonsQuery } from '../store/sunbright-api';
import { useAppSelector } from "../store/store";

const cellWithCheck = (props: GridCellProps) => {
    const active = props.dataItem.active;

    const style = {
        color: "green",
    };
    return (
        <td style={style} data-grid-col-index={5}>
            {active === "1" ? <span className="k-icon k-font-icon k-i-check" /> : null}
        </td>
    );
};

const initialDataStateVet: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "role", operator: "contains", value: "Veterinarian" },
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};
const initialDataStateCw: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "role", operator: "contains", value: "Case Worker" },
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};
const initialDataStateClient: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "role", operator: "contains", value: "Client" },
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};
const initialDataStateIntake: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "role", operator: "contains", value: "Intake" },
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};
const initialDataStateAdmin: State = {
    filter: {
        logic: "and",
        filters: [
            { field: "role", operator: "contains", value: "Admin" },
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const roles: string[] = ["Case Worker", "Client", "Veterinarian", "Intake", "Admin"];   // Clients get added when exam added

const PersonsGrid = (props: any) => {
    const auth = useAppSelector((state: any) => state.auth);

    const role = React.useState<string>(auth && (auth.user !== undefined) && (auth.user.role !== undefined)) ? auth.user.role : "";
    const [isAdmin, setIsAdmin] = React.useState<boolean>(role.includes("Admin"));

    const { data, error, isLoading } = useGetPersonsQuery("test");

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 120) + "px");
    }, [data]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 120) + "px");
    };

    const [dataStateVet, setDataStateVet] = React.useState<State>(initialDataStateVet);
    const [dataStateCw, setDataStateCw] = React.useState<State>(initialDataStateCw);
    const [dataStateClient, setDataStateClient] = React.useState<State>(initialDataStateClient);
    const [dataStateIntake, setDataStateIntake] = React.useState<State>(initialDataStateIntake);
    const [dataStateAdmin, setDataStateAdmin] = React.useState<State>(initialDataStateAdmin);

    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    const handleSelectTab = (e: TabStripSelectEventArguments) => {
        setSelectedTab(e.selected);
    };

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const [isNewPerson, setIsNewPerson] = React.useState<boolean>(false);

    const [selectedStateCw, setSelectedStateCw] = React.useState<{ [id: string]: boolean | number[]; }>({});
    const [selectedStateClient, setSelectedStateClient] = React.useState<{ [id: string]: boolean | number[]; }>({});
    const [selectedStateVet, setSelectedStateVet] = React.useState<{ [id: string]: boolean | number[]; }>({});
    const [selectedStateIntake, setSelectedStateIntake] = React.useState<{ [id: string]: boolean | number[]; }>({});
    const [selectedStateAdmin, setSelectedStateAdmin] = React.useState<{ [id: string]: boolean | number[]; }>({});

    const handleSelectionChangeCw = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateCw,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateCw(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };
    const handleSelectionChangeClient = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateClient,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateClient(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };
    const handleSelectionChangeVet = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateVet,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateVet(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };
    const handleSelectionChangeIntake = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateIntake,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateIntake(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };
    const handleSelectionChangeAdmin = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedStateAdmin,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedStateAdmin(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };

    return (
        <React.Fragment>
            {(selectedUID.length === 0) ?
                ((isAdmin && isNewPerson) ?
                    <div>
                        <div className="sds-gridHeader">{"New " + roles[selectedTab]}</div>
                        <PersonNew
                            role={roles[selectedTab]}
                            onReturn={(refresh: boolean) => setIsNewPerson(false)}
                        /> 
                    </div>
                    :
                    <div>
                        {(isAdmin && (roles[selectedTab].length > 0) && (roles[selectedTab] !== "Intake")) &&
                            <Button
                                themeColor={"primary"}
                                icon="plus-circle"
                                onClick={() => setIsNewPerson(true)}
                                style={{ position: "absolute", top: "46px", right: "64px", width: "160px", zIndex: "99" }}>
                                {"New " + roles[selectedTab]}
                            </Button>
                        }
                        {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? (
                        <div>
                            <div className="sds-gridHeader">People</div>
                            <TabStrip selected={selectedTab} onSelect={handleSelectTab} style={{ paddingTop: "4px" }}>
                                <TabStripTab title="Case Workers">
                                    <Grid
                                        style={{ height: gridHeight }}
                                        className="personGrid sds-selectable"
                                        data={process(data.map((item: any) => ({
                                            ...item,
                                            [SELECTED_FIELD]: selectedStateCw[idGetter(item)]
                                        })), dataStateCw)}
                                        {...dataStateCw}
                                        onDataStateChange={(e: GridDataStateChangeEvent) => { setDataStateCw(e.dataState); }}
                                        sortable={true}
                                        filterable={true}
                                        resizable={true}
                                        reorderable={true}
                                        selectedField={SELECTED_FIELD}
                                        selectable={{
                                            enabled: true,
                                            mode: "single",
                                        }}
                                        onSelectionChange={handleSelectionChangeCw}
                                        size={"small"}
                                    >
                                        <GridColumn field="name" title="Name" filterable={true} filter="text" />
                                        <GridColumn field="username" title="Username" filterable={false} filter="text" />
                                        <GridColumn field="email" title="Email" filterable={false} filter="text" />
                                        <GridColumn field="agency" title="Agency" filterable={true} filter="text" />
                                        <GridColumn field="role" title="Role" filterable={false} />
                                        <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck} />
                                    </Grid>
                                </TabStripTab>
                                <TabStripTab title="Clients">
                                    <Grid
                                        style={{ height: gridHeight }}
                                        className="clientGrid"
                                        data={process(data.map((item: any) => ({
                                            ...item,
                                            [SELECTED_FIELD]: selectedStateClient[idGetter(item)]
                                        })), dataStateClient)}
                                        {...dataStateClient}
                                        onDataStateChange={(e: GridDataStateChangeEvent) => { setDataStateClient(e.dataState); }}
                                        sortable={true}
                                        filterable={true}
                                        resizable={true}
                                        reorderable={true}
                                        selectedField={SELECTED_FIELD}
                                        selectable={{
                                            enabled: true,
                                            mode: "single",
                                        }}
                                        onSelectionChange={handleSelectionChangeClient}
                                        size={"small"}
                                    >
                                        <GridColumn field="name" title="Name" filterable={true} filter="text" />
                                        <GridColumn field="email" title="Email" filterable={true} filter="text" />
                                        <GridColumn field="agency" title="Agency" filterable={true} filter="text" />
                                        <GridColumn field="role" title="Role" filterable={false} />
                                        <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck} />
                                    </Grid>
                                </TabStripTab>
                                <TabStripTab title="Veterinarians">
                                    <Grid
                                        style={{ height: gridHeight }}
                                        className="personGrid"
                                        data={process(data.map((item: any) => ({
                                            ...item,
                                            [SELECTED_FIELD]: selectedStateVet[idGetter(item)]
                                        })), dataStateVet)}
                                        {...dataStateVet}
                                        onDataStateChange={(e: GridDataStateChangeEvent) => { setDataStateVet(e.dataState); }}
                                        sortable={true}
                                        filterable={true}
                                        resizable={true}
                                        reorderable={true}
                                        selectedField={SELECTED_FIELD}
                                        selectable={{
                                            enabled: true,
                                            mode: "single",
                                        }}
                                        onSelectionChange={handleSelectionChangeVet}
                                        size={"small"}
                                    >
                                        <GridColumn field="name" title="Name" filterable={true} filter="text" />
                                        <GridColumn field="username" title="Username" filterable={true} filter="text" />
                                        <GridColumn field="email" title="Email" filterable={true} filter="text" />
                                        <GridColumn field="credentials" title="Credentials" filterable={true} filter="text" />
                                        <GridColumn field="role" title="Role" filterable={false} />
                                        <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck} />
                                    </Grid>
                                </TabStripTab>
                                    <TabStripTab title="Intake">
                                        <Grid
                                            style={{ height: gridHeight }}
                                            className="personGrid sds-selectable"
                                            data={process(data.map((item: any) => ({
                                                ...item,
                                                [SELECTED_FIELD]: selectedStateIntake[idGetter(item)]
                                            })), dataStateIntake)}
                                            {...dataStateIntake}
                                            onDataStateChange={(e: GridDataStateChangeEvent) => { setDataStateIntake(e.dataState); }}
                                            sortable={true}
                                            filterable={true}
                                            resizable={true}
                                            reorderable={true}
                                            selectedField={SELECTED_FIELD}
                                            selectable={{
                                                enabled: true,
                                                mode: "single",
                                            }}
                                            onSelectionChange={handleSelectionChangeIntake}
                                            size={"small"}
                                        >
                                            <GridColumn field="name" title="Name" filterable={true} filter="text" />
                                            <GridColumn field="username" title="Username" filterable={true} filter="text" />
                                            <GridColumn field="email" title="Email" filterable={true} filter="text" />
                                            <GridColumn field="credentials" title=" " filterable={false} />
                                            <GridColumn field="role" title="Role" filterable={false} />
                                            <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck} />
                                        </Grid>
                                    </TabStripTab>
                                    <TabStripTab title="Admin">
                                        <Grid
                                            style={{ height: gridHeight }}
                                            className="personGrid sds-selectable"
                                            data={process(data.map((item: any) => ({
                                                ...item,
                                                [SELECTED_FIELD]: selectedStateAdmin[idGetter(item)]
                                            })), dataStateAdmin)}
                                            {...dataStateAdmin}
                                            onDataStateChange={(e: GridDataStateChangeEvent) => { setDataStateAdmin(e.dataState); }}
                                            sortable={true}
                                            filterable={true}
                                            resizable={true}
                                            reorderable={true}
                                            selectedField={SELECTED_FIELD}
                                            selectable={{
                                                enabled: true,
                                                mode: "single",
                                            }}
                                            onSelectionChange={handleSelectionChangeAdmin}
                                            size={"small"}
                                        >
                                            <GridColumn field="name" title="Name" filterable={true} filter="text" />
                                            <GridColumn field="username" title="Username" filterable={true} filter="text" />
                                            <GridColumn field="email" title="Email" filterable={true} filter="text" />
                                            <GridColumn field="credentials" title=" " filterable={false} />
                                            <GridColumn field="role" title="Role" filterable={false} />
                                            <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck} />
                                        </Grid>
                                    </TabStripTab>
                                    </TabStrip>
                        </div>) : null}
                </div> ) :
                <PersonView
                    personID={selectedUID}
                    role={roles[selectedTab]}
                    viewOnly={false }
                    returnLabel="Return to People"
                    onReturn={() => setSelectedUID("")}
                />
            }
        </React.Fragment>
    );
}
export default PersonsGrid;
