import * as React from "react";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FieldWrapper,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";

import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";

import PersonNew from './PersonNew'
import PersonPassword from './PersonPassword'

import { useAppSelector } from "../store/store";
import { useGetPersonQuery } from '../store/sunbright-api';

export interface PersonViewProps {
    personID: string;
    role: string;
    viewOnly: boolean;
    returnLabel: string;
    onReturn?: any;
}

const PersonView = ({
    personID,
    role,
    viewOnly,
    returnLabel,
    onReturn,
}: PersonViewProps) => {
    const auth = useAppSelector((state) => state.auth);
    const [canEdit, setCanEdit] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        auth.user.role.includes("Admin") && (role !== "") && !viewOnly);

    const [hasPassword, setHasPassword] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        auth.user.role.includes("Admin") && (role !== "Client") && !viewOnly);

    const [isEdit, setIsEdit] = React.useState<boolean>(false);
    const [isPassword, setIsPassword] = React.useState<boolean>(false);

    const { data, error, isLoading } = useGetPersonQuery(personID);

    const [personData, setPersonData] = React.useState<any>(null);

    React.useEffect(() => {
        if (data) {
            setPersonData(data);
        }
    }, [data]);

    const editReturned = (refresh: boolean) => {
        setIsEdit(false);
        if (refresh) {
            setPersonData(null);
        }
    }

    return (
        <React.Fragment>
            <div className="sds-window">
                {error ? (<>Not Authorized</>) : isLoading ? <Loader size="large" type="converging-spinner" /> : personData ? (
                    <div>
                        <Toolbar>
                            <ButtonGroup>
                                    <Button
                                        icon="undo"
                                        onClick={(e) => onReturn() }
                                    >
                                        {returnLabel}
                                    </Button>
                            </ButtonGroup>
                            <div style={{ fontSize: "large", fontWeight: "bold", color:"darkblue", paddingLeft: "64px" }}>
                                {role}
                            </div>
                        </Toolbar>
                        {canEdit && (
                            <div style={{ position: "fixed", top: "64px", right: "34px", zIndex: "99" }}>
                                <ButtonGroup>
                                    {canEdit &&
                                        <Button
                                            icon="edit"
                                            themeColor={"primary"}
                                            onClick={() => setIsEdit(true)}>
                                            Edit
                                        </Button>
                                    }
                                    {hasPassword &&
                                        <Button
                                            icon="lock"
                                            style={{ borderLeftColor: "white", borderLeftWidth: "1px" }}
                                            disabled={false}
                                            onClick={() => setIsPassword(true)}
                                            themeColor={"primary"}>
                                            Reset Password
                                        </Button>
                                    }
                                </ButtonGroup>
                            </div>)}
                        {isEdit ?
                            <Dialog contentStyle={{ width: "1170px" }}>
                                <PersonNew
                                    role={role}
                                    personData={data}
                                    onReturn={(refresh: boolean) => editReturned(refresh)}
                                />
                            </Dialog>
                            :
                            (isPassword ?
                                <Dialog contentStyle={{ width: "300px" }}>
                                    <PersonPassword
                                        role={role}
                                        personData={data}
                                        onReturn={(refresh: boolean) => setIsPassword(false)}
                                    />
                                </Dialog>
                                :
                                <Form
                                    initialValues={{
                                        name: personData.name,
                                        mobile: personData.mobilePhone,
                                        local: personData.localPhone,
                                        email: personData.email,
                                        address1: personData.address1,
                                        city: personData.city,
                                        province: personData.province,
                                        postal: personData.postalCode,
                                        role: personData.role,
                                        credentials: personData.credentials,
                                        agency: personData.agency,
                                        username: personData.username,

                                    }}
                                    render={(formRenderProps: FormRenderProps) => (
                                        <div>
                                            <FormElement style={{ margin: "16px" }}>
                                                <fieldset
                                                    className={"k-form-fieldset"}
                                                >
                                                    <StackLayout gap={16}>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Name</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"name"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Mobile Phone</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"mobile"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Local Phone</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"local"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Email</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"email"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                    </StackLayout>
                                                    <StackLayout gap={16}>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Address</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"address1"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">City</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"city"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Province</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"province"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Postal Code</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"postal"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                    </StackLayout>
                                                    <StackLayout gap={16}>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Role</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"role"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Credentials</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"credentials"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Agency</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"agency"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                        <FieldWrapper>
                                                            <Label className="k-form-label">Username</Label>
                                                            <div className="k-form-field-wrap">
                                                                <Field
                                                                    name={"username"}
                                                                    component={Input}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </FieldWrapper>
                                                    </StackLayout>
                                                </fieldset>
                                            </FormElement>
                                        </div>
                                    )}
                                />
                            )
                        }
                    </div>)
                    :
                    null
                }
                </div>
       </React.Fragment>
    );
};
export default PersonView;
