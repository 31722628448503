import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";
import { getter } from "@progress/kendo-react-common";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridDataStateChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

import {
    process,
    State,
} from "@progress/kendo-data-query";

import AgencyView from "./AgencyView";
import AgencyNew from './AgencyNew'
import NavigateToLogin from "./NavigateToLogin";

import { useGetAgenciesQuery } from '../store/sunbright-api';
import { useAppSelector } from "../store/store";

const cellWithCheck1 = (props: GridCellProps) => {
    const isChecked:boolean = ((props.field === "emailReport") && (props.dataItem.emailReport === "1")) ||
        ((props.field === "active") && (props.dataItem.active === "1"));

    const style = {
        color: "green",
    };
    return (
        <td style={style} data-grid-col-index={2}>
            {isChecked ? <span className="k-icon k-font-icon k-i-check" /> : null}
        </td>
    );
};
const cellWithCheck2 = (props: GridCellProps) => {
    const isChecked: boolean = ((props.field === "emailReport") && (props.dataItem.emailReport === "1")) ||
        ((props.field === "active") && (props.dataItem.active === "1"));

    const style = {
        color: "green",
    };
    return (
        <td style={style} data-grid-col-index={7}>
            {isChecked ? <span className="k-icon k-font-icon k-i-check" /> : null}
        </td>
    );
};


const initialDataState: State = {
    filter: {
        logic: "and",
        filters: [
        ],
    },
    sort: [{ field: "name", dir: "asc" }],
};

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const AgenciesGrid = (props: any) => {
    const auth = useAppSelector((state: any) => state.auth);

    const role = React.useState<string>(auth && (auth.user !== undefined) && (auth.user.role !== undefined)) ? auth.user.role : "";
    const [isAdmin, setIsAdmin] = React.useState<boolean>(role.includes("Admin"));

    const { data, error, isLoading } = useGetAgenciesQuery("test");

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 100) + "px");
    }, [data]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };
    const [isNewAgency, setIsNewAgency] = React.useState<boolean>(false);

    const [dataState, setDataState] = React.useState<State>(initialDataState);

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };

    return (
        <React.Fragment>
            {(selectedUID.length === 0) ?
                ((isAdmin && isNewAgency) ? 
                    <div>
                        <div className="sds-gridHeader">{"New Agency"}</div>
                        <AgencyNew
                            onReturn={(refresh: boolean) => setIsNewAgency(false)}
                        /> 
                    </div>
                    :
                    <div>
                        {(isAdmin ) &&
                            <Button
                                themeColor={"primary"}
                                icon="plus-circle"
                                onClick={() => setIsNewAgency(true)}
                                style={{ position: "absolute", top: "46px", right: "64px", width: "160px", zIndex: "99" }}>
                                {"New Agency"}
                            </Button>
                        }
                        {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? 
                        <div>
                            <div className="sds-gridHeader">Agencies</div>
                            <Grid
                                style={{ height: gridHeight}}
                                className="agencyGrid sds-selectable"
                                data={process(data.map((item: any) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                                })), dataState)}
                                {...dataState}
                                onDataStateChange={(e: GridDataStateChangeEvent) => { setDataState(e.dataState); }}
                                sortable={true}
                                filterable={true}
                                resizable={true}
                                reorderable={true}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    mode: "single",
                                }}
                                onSelectionChange={handleSelectionChange}
                               size={"small"}
                            >
                                <GridColumn field="name" title="Name" filterable={true} filter="text" />
                                <GridColumn field="phone" title="Phone" filterable={false}/>
                                <GridColumn field="emailReport" title="Auto-Email" filterable={false} cell={cellWithCheck1} />
                                <GridColumn field="petsActive" title="Active Pets" filterable={false} />
                                <GridColumn field="examsTotal" title="Exams Total" filterable={false} />
                                <GridColumn field="examsLastYear" title="Exams Last Year" filterable={false} />
                                <GridColumn field="examsThisYear" title="Exams This Year" filterable={false} />
                                <GridColumn field="active" title="Active" filterable={false} cell={cellWithCheck2} />
                                    </Grid>)
                        </div>
                        : null}
                    </div>
                )
                :
                < AgencyView
                    agencyID={selectedUID}
                    viewOnly={false }
                    returnLabel="Return to Agencies"
                    onReturn={() => setSelectedUID("")}
                />
            }
        </React.Fragment>
    );
}

export default AgenciesGrid;
