import React from "react";
import { useAppSelector } from "../store/store";
import { Navigate } from 'react-router-dom';

export interface PageProps {
    element?: React.ReactNode;
}

const AuthGuard = ({
    element,
}: PageProps) => {
    const auth = useAppSelector((state) => state.auth);

    const isAuthenticated = auth && (auth.user !== undefined) && (auth.user.username) && (auth.user.username.length > 0);
    //const isAuthenticated = true;

    return (
        <>
            {isAuthenticated && (
                element
            )}
            {!isAuthenticated && (
                <Navigate to="/login"/>
            )}
        </>
    );
}

export default AuthGuard;
