﻿import * as React from "react";
import {
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";

import ExamNewClientPet from "./ExamNewClientPet";
import ExamNewEligibility from "./ExamNewEligibility";
import ExamNewWaivers from "./ExamNewWaivers";
import ExamNewQuestionnaire from "./ExamNewQuestionnaire";
import ExamNewComments from "./ExamNewComments";

import { useNewExamMutation } from '../../store/sunbright-api';

export interface ExamNewProps {
    onReturn?: any;
}
interface stepsInterface {
    isValid: boolean | undefined;
    label: string;
    icon: string;
    optional?: boolean;
}

const ExamNew = ({
    onReturn,
}: ExamNewProps) => {

    const [addNewExam, response] = useNewExamMutation();

    const ExamNewForm = React.useRef<any>(null);
    const FormDiv = React.useRef<any>(null);

    const [formHeight, setFormHeight] = React.useState<string>("100px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        setFormHeight((window.innerHeight - 190) + "px");
    }, []);

    const handleResize = () => {
        setFormHeight((window.innerHeight - 190) + "px");
    };

    const [clientName, setClientName] = React.useState<string>("XX");
    const [petName, setPetName] = React.useState<string>("YY");

    const stepPages = [
        ExamNewClientPet({ newExam:true, form: ExamNewForm }),
        ExamNewEligibility(),
        ExamNewWaivers({ clientName: clientName, petName: petName }),
        ExamNewQuestionnaire(),
        ExamNewComments({ newExam: true, examID:"" })
    ];

    const [step, setStep] = React.useState(0);
    const [formState, setFormState] = React.useState<Object>({});
    const [steps, setSteps] = React.useState<Array<stepsInterface>>([
        {
            label: "Owner/Pet",
            icon: "k-i-info-circle",
            isValid: undefined,
        },
        {
            label: "Eligibility",
            icon: "k-i-user",
            isValid: undefined,
        },
        {
            label: "Waivers",
            icon: "k-i-signature",
            isValid: undefined,
        },
        {
            label: "Questionnaire",
            icon: "k-i-question-circle",
            isValid: undefined,
        },
        {
            label: "Comments",
            icon: "k-i-comment",
            isValid: undefined,
        },
    ]);

    const [submitted, setSubmitted] = React.useState<boolean>(false);

    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;

    const onStepSubmit = React.useCallback(
        (event: FormSubmitClickEvent) => {
            const { isValid, values } = event;

            const currentSteps = steps.map(
                (currentStep: stepsInterface, index: number) => ({
                    ...currentStep,
                    isValid: index === step ? isValid : currentStep.isValid,
                })
            );

            setSteps(currentSteps);

            if (!isValid) {
                return;
            }

            if ((step === 0) && ExamNewForm && ExamNewForm.current && ExamNewForm.current.values) {
                setClientName(ExamNewForm.current.values.ownerfirst + " " + ExamNewForm.current.values.ownerlast);
                setPetName(ExamNewForm.current.values.petname);
            }

            setStep(() => Math.min(step + 1, lastStepIndex));
            setFormState(values);

            FormDiv.current.children[0].children[0].scrollTop = 0;

            if (isLastStep) {
                const postExam = ExamNewForm.current.values;
                postExam.category = "New";

                addNewExam(postExam)
                    .unwrap()
                    .then(() => { })
                    .catch((error) => {
                        console.log(error)
                    })

                setSubmitted(true);
            }
        },
        [steps, isLastStep, step, lastStepIndex]
    );

    const onPrevClick = React.useCallback(
        (event:any) => {
            event.preventDefault();
            setStep(() => Math.max(step - 1, 0));

            FormDiv.current.children[0].children[0].scrollTop = 0;
        },
        [step, setStep]
    );

    return (
        <React.Fragment>
        <div>
            <Toolbar>
                <ButtonGroup>
                        <Button
                            icon="undo"
                            onClick={(e) => onReturn() }
                        >
                            Return to Exams
                        </Button>
                </ButtonGroup>
                </Toolbar>

                <Stepper value={step} items={steps} />

                <div
                    ref={ FormDiv }
                    style={{ overflow: "auto", marginLeft:"16px" }}
                    className={"sds-bold" }
                >
                    <Form
                        ref={ ExamNewForm }
                        initialValues={formState}
                        onSubmitClick={onStepSubmit}
                        render={(formRenderProps: FormRenderProps) => (
                            <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{ height: formHeight, overflow: "auto", marginLeft: "16px", marginRight:"32px" }}>
                                    {stepPages[step]}
                                </FormElement>
                                <ButtonGroup width="95%" >
                                    <Button
                                        style={{
                                            margin: "4px",
                                            opacity: 0.9,
                                        }}
                                        themeColor="primary"
                                        disabled={step === 0}
                                        onClick={onPrevClick}
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        style={{
                                            margin: "4px",
                                            opacity: isLastStep ? 1.0 : 0.9,
                                            fontWeight: isLastStep ? "bold" : "normal",
                                        }}
                                        themeColor="primary"
                                        disabled={!formRenderProps.allowSubmit}
                                        onClick={formRenderProps.onSubmit}
                                    >
                                        {isLastStep ? "Submit" : "Next"}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        )}
                    />
                </div>
            </div>
            {(submitted &&
                <Dialog
                    title={"Submitted"}
                    onClose={onReturn}
                >
                    <p style={{ margin: "25px", textAlign: "center" }}>
                        Thank you for your submission.<br/>
                    </p>
                </Dialog>
            )}
       </React.Fragment>
    );
};
export default ExamNew;
