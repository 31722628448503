import * as React from "react";

import { Field } from "@progress/kendo-react-form";
import { UploadOnAddEvent } from '@progress/kendo-react-upload';
import {
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import {
    Grid,
    GridColumn,
    GridSelectionChangeEvent,
    getSelectedState,
    GridCellProps,
} from "@progress/kendo-react-grid";

import { getter } from "@progress/kendo-react-common";

import { FormUpload } from "../FormComponents";

import { useUploadFileMutation } from '../../store/sunbright-api';

export interface ExamViewAttachmentsProps {
    examID: string;
    fields: any;
    category: string;
}


const ExamViewAttachments = ({
    examID,
    fields,
    category,
    ...windowProps
}: ExamViewAttachmentsProps) => {

    const linkCell = (props: GridCellProps) => {
        const filename = props.dataItem.Filename;
        const regionID = props.dataItem.RegionID;

        return (
            <td data-grid-col-index={0}>
                <a href={"https://sunbrightstorage.blob.core.windows.net/" + regionID + "-" + examID + "/" + filename}>{filename}</a>
            </td >
        );
    };

    const [uploadFile, response] = useUploadFileMutation();

    const fileAdded = (e: UploadOnAddEvent) => {

        let file = e?.affectedFiles[0]?.getRawFile ? e?.affectedFiles[0]?.getRawFile() : undefined;
        var bodyFormData: any = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('examID', examID);

        uploadFile(bodyFormData)
            .unwrap()
            .then((payload) => { })
            .catch((error) => {
                console.log(error)
            });
    } 

    return (
        <React.Fragment>
            <Form
             render={(formRenderProps: FormRenderProps) => (
                <div className={"sds-questionnaire"} style={{ marginTop: "4px" }}>
                        <div style={{ alignSelf: "center" }}>
                            <FormElement
                                style={{ overflow: "auto", marginLeft: "16px", marginRight: "32px" }}>
                                <div className={"sds-questionnaire"} style={{ marginTop: "4px" }}>
                                   <fieldset
                                    style={{}}
                                    className={"k-form-fieldset"}
                                    >
                                        <Field
                                            label={"Attachments"}
                                            name={"attachments"}
                                            component={FormUpload}
                                            onAdd={fileAdded}
                                        />
                                    </fieldset>
                                </div>
                            </FormElement>
                        </div>
                    </div>
                )}
            />
            <p />
            <Grid
                style={{ height: "400px", border:"1px solid" }}
                className="sds-selectable"
                data={fields.map((item: any) => ({
                    Filename: item.result.a,
                    RegionID: item.result.n,
                }))}
                resizable={false}
                reorderable={false}
                size={"small"}
            >
                <GridColumn field="Filename" filterable={false} cell={linkCell} />
            </Grid>

        </React.Fragment>
    );
};
export default ExamViewAttachments;
