import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";

import {
    Grid,
    GridColumn,
    GridCellProps,
    GridDataStateChangeEvent,
} from "@progress/kendo-react-grid";

import {
    process,
    State,
} from "@progress/kendo-data-query";

import NavigateToLogin from "./NavigateToLogin";

import { useGetFindingsQuery } from '../store/sunbright-api';

const initialDataState: State = {
    filter: {
        logic: "and",
        filters: [
        ],
    },
    sort: [{ field: "uid", dir: "asc" }],
};

const FindingsGrid = (props: any) => {
    const { data, error, isLoading } = useGetFindingsQuery("test");

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 100) + "px");
    }, [data]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [dataState, setDataState] = React.useState<State>(initialDataState);

    return (
        <React.Fragment>
            <div className="sds-gridHeader">Findings</div>
            {error ? (<NavigateToLogin />) : isLoading ? <Loader size="large" type="converging-spinner" /> : data ? (
                <Grid
                    style={{ height: gridHeight}}
                    className="findingGrid"
                    data={process(data, dataState)}
                    {...dataState}
                    onDataStateChange={(e: GridDataStateChangeEvent) => { setDataState(e.dataState); }}
                    sortable={true}
                    filterable={true}
                    resizable={true}
                    reorderable={true}
                    size={"small"}
                >
                    <GridColumn field="uid" title="ID" filterable={false} filter="numeric" />
                    <GridColumn field="label" title="Label" filterable={true} filter="text" />
                </Grid>) : null}
        </React.Fragment>
    );
}
export default FindingsGrid;
