import * as React from "react";

import ApplicationBar from "./ApplicationBar";
import ApplicationDrawer from "./ApplicationDrawer";

const Home = (props: any) => {

    return (
        <React.Fragment>
            <ApplicationBar />
            <ApplicationDrawer />
        </React.Fragment>
    );
}
export default Home;
