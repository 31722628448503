export interface FloatingContainerLabelProps {
    label?: string | React.ReactNode;
}

const FloatingContainerLabel = ({
    label,
    children,
    ...props
}: FloatingContainerLabelProps & React.FieldsetHTMLAttributes<any>) => {
    return (
        <fieldset
            {...props}
            aria-hidden
            style={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                padding: "18.5px 14px",
                borderRadius: "18px",
                ...props.style,
            }}
        >
            <legend
                style={{
                    padding: 0,
                    marginBottom: -22,
                }}
            >
                {label}
            </legend>
            {children}
        </fieldset>
    );
};

export default FloatingContainerLabel;
