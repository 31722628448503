import * as React from "react";

import { useAppDispatch, useAppSelector } from "../store/store";
import { logout } from "../store/auth-slice";
import { Navigate } from 'react-router-dom';
import { sunbrightApi } from '../store/sunbright-api';

const NavigateToLogin = (props: any) => {

    const dispatch = useAppDispatch();

    const logoutAndReset = () => {
        dispatch(
            logout({
                user: {},
            }));

        dispatch(sunbrightApi.util.resetApiState());

        return "/login";
    }

    return (
        <Navigate to={logoutAndReset()} />
    );
};

export default NavigateToLogin;
