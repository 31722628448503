import * as React from "react";

import {
    Grid,
    GridColumn,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";

import { getter } from "@progress/kendo-react-common";

import { useAppSelector } from "../store/store";

const userHowTos = [
    {
        "ID": 1,
        "Title": "How to create a new exam.",
        "Document": "https://sunbrightstorage.blob.core.windows.net/howtos/AddAnExam.mp4"
    }
]
const adminHowTos = [
    {
        "ID": 1,
        "Title": "How to create a new exam.",
        "Document": "https://sunbrightstorage.blob.core.windows.net/howtos/AddAnExam.mp4"
    },
    {
        "ID": 2,
        "Title": "How to schedule an exam.",
        "Document": "https://sunbrightstorage.blob.core.windows.net/howtos/ScheduleAnExam.mp4"
    },
    {
        "ID": 3,
        "Title": "How to add an agency.",
        "Document": "https://sunbrightstorage.blob.core.windows.net/howtos/AddAnAgency.mp4"
    },
    {
        "ID": 4,
        "Title": "How to add a case worker.",
        "Document": "https://sunbrightstorage.blob.core.windows.net/howtos/AddACaseWorker.mp4"
    },
    {
        "ID": 5,
        "Title": "How to reset a password.",
        "Document": "https://sunbrightstorage.blob.core.windows.net/howtos/ResetPassword.mp4"
    }
]

const DATA_ITEM_KEY = "ID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const UserGuideGrid = (props: any) => {
    const auth = useAppSelector((state) => state.auth);
    const [howTos, setHowTos] = React.useState((auth && (auth.user !== undefined) && auth.user.role && (auth.user.role.includes("Admin"))) ? adminHowTos : userHowTos);

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        setGridHeight((window.innerHeight - 100) + "px");
    }, []);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const onGridSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let [key, value] = Object.entries(newSelectedState)[0];
        if (value === true) {
            let url = howTos[+key - 1].Document;
            window.open(url);
        }
    }; 

    return (
        <React.Fragment>
            <div className="sds-gridHeader">User Guide</div>
            <p style={{ color: "red", fontStyle: "italic", fontSize:"smaller" }}>
                Videos will open in a new tab.
            </p>
          <Grid
                style={{ height: gridHeight }}
                className="sds-selectable"
                data={howTos.map((item:any) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                resizable={true}
                reorderable={true}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "single",
                }}
                onSelectionChange={onGridSelectionChange}
                size={"small"}
            >
                <GridColumn field="Title" filterable={true} filter="text"/>
            </Grid>
        </React.Fragment>
    );
}
export default UserGuideGrid;
