﻿import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";

import {
    Grid,
    GridColumn,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";

import EventNew from "./EventNew";
import EventView from "./EventView";

import { useGetFollowUpsQuery } from '../store/sunbright-api';

const DATA_ITEM_KEY = "uid";
const SELECTED_FIELD = "selected";

const EventsGrid = (props: any) => {
    const { data: followups, error: followupsError, isLoading: followupsLoading } = useGetFollowUpsQuery("");

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 120) + "px");
    }, [followups]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [newFollowUp, setNewFollowUp] = React.useState<boolean>(false);

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const [selectedUID, setSelectedUID] = React.useState<string>("");

    const handleSelectionChange = (e: GridSelectionChangeEvent) => {
        const newState = getSelectedState({
            event: e,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        })
        setSelectedState(newState);

        setSelectedUID(Object.keys(newState)[0]);
    };


    return (
        <React.Fragment>
            {(selectedUID.length !== 0) ?
                (< EventView
                    eventID={+selectedUID}
                    onReturn={() => setSelectedUID("")}
                />
                ) :
            (newFollowUp ?
                (<div>
                    <EventNew 
                        onReturn={(submitted: boolean) => setNewFollowUp(false)}
                    />
                </div>) :
                (<div>
                    <div className="sds-gridHeader">Event Follow Up</div>
                    {followupsError ? (<>Not Authorized</>) : followupsLoading ? <Loader size="large" type="converging-spinner" /> : followups ? (
                    <div>
                        <Button
                            themeColor={"primary"}
                            icon="plus-circle"
                            onClick={() => setNewFollowUp(true)}
                            style={{ position: "absolute", top: "46px", right: "64px", width: "128px", zIndex:"99" }}>
                        New Follow Up
                        </Button>
                        <Grid
                            style={{ height: gridHeight }}
                            className="eventsGrid sds-selectable"
                            data={followups}
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            reorderable={true}
                            size={"small"}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                mode: "single",
                            }}
                            onSelectionChange={handleSelectionChange}
                       >
                            <GridColumn field="startTime" title="Date" filterable={false} format="{0:MMM d yyyy}" />
                            <GridColumn field="type" title="Type" filterable={true} filter="text" />
                            <GridColumn field="host" title="Host/Location" filterable={true} filter="text" />
                            <GridColumn field="region" title=" " filterable={false} filter="text" />
                            </Grid>
                        </div>
                    ) : null}
                </div>)
            )}
        </React.Fragment>
    );
}
export default EventsGrid;
