import * as React from "react";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";

import { StackLayout } from "@progress/kendo-react-layout";
import { requiredValidator } from "./validators";

import { FormDatePicker, FormDropDownList, FormInput, FormCheckbox } from "./FormComponents";

import { useNewFollowUpMutation } from '../store/sunbright-api';

const clinicTypes = [
    "One Health Wellness Clinic",
    "Pet Fair & People Care",
    "Spay Neuter Clinic",
    "Booster Clinic",
];

export interface EventNewProps {
    followUpData?: any;
    onReturn: any;
}

const EventNew = ({
    followUpData,
    onReturn,
}: EventNewProps) => {

    const [newFollowUp, response] = useNewFollowUpMutation();

    const FollowUpForm = React.useRef<any>(null);

    const [formHeight, setFormHeight] = React.useState<string>("100px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        setFormHeight((window.innerHeight - 90) + "px");
    }, []);

    const handleResize = () => {
        setFormHeight((window.innerHeight - 90) + "px");
    };

    const findingValues: any = (followUpData: any) => {

        if (followUpData && followUpData.fields && (followUpData.fields != null)) {

            const oneHealthFields: number[] = [354, 355, 356, 357, 358, 359, 360, 378, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 378, 380, 381, 383];
            const hoursFields: number[] = [349, 350, 351, 352, 353];
            var findings = followUpData.fields.reduce(function (result: any, field: any) {
                var answerKey: string = "";

                if (field.t === "grid") {
                    for (field of field.results) {
                        if (oneHealthFields.includes(field.findingID)) {
                            result["A" + field.findingID] = field.a;
                            result["B" + field.findingID] = true;
                        } else if (hoursFields.includes(field.findingID)) {
                            result["A" + field.findingID] = field.a;
                        }
                    }
                }

                return result;
            }, {})

            findings.starttime = new Date(followUpData.starttime);
            findings.type = followUpData.type;
            findings.host = followUpData.host;

            return findings;
        } else {
            return [];
        }


    };

    const submitForm = (event: FormSubmitClickEvent) => {

        const { isValid } = event;

        if (isValid) {
            const postFollowUp = FollowUpForm.current.values;

            postFollowUp.uid = followUpData ? followUpData.id : 0;

            newFollowUp(postFollowUp)
                .unwrap()
                .then((payload) => { onReturn(true) })
                .catch((error) => {
                    console.log(error)
                });
        }
    }

    return (
        <React.Fragment>

            <div
                style={{ overflow: "auto", marginLeft: "16px" }}
                className={"sds-bold"}
            >
                <Form
                    ref={FollowUpForm}
                    initialValues={findingValues(followUpData)}
                    onSubmitClick={submitForm}
                    render={(formRenderProps: FormRenderProps) => (
                        <div>
                             <Toolbar>
                                    <Button
                                    icon="upload"
                                        onClick={formRenderProps.onSubmit}
                                    >
                                        Submit
                                    </Button>
                                <ButtonGroup>
                                    <Button
                                        icon="cancel"
                                        onClick={(e) => onReturn(false)}
                                    >
                                        Cancel
                                    </Button>
                                </ButtonGroup>
                            </Toolbar>
                           <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{ height: formHeight, overflow: "auto", marginLeft: "16px", marginRight: "32px" }}>
                                    <div className={"sds-questionnaire"}>
                                        <fieldset
                                            style={{}}
                                            className={"k-form-fieldset"}
                                        >
                                            <legend
                                                style={{ paddingTop: "24px" }}
                                                className={"k-form-legend"}>
                                                Event
                                            </legend>

                                            <StackLayout gap={24} style={{ marginTop: "-24px" } }>
                                                <Field
                                                    label={"Event Date*"}
                                                    name={"starttime"}
                                                    component={FormDatePicker}
                                                    placeholder=""
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Type*"}
                                                    name={"type"}
                                                    component={FormDropDownList}
                                                    data={clinicTypes}
                                                   validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Primary Host Agency / Location*"}
                                                    name={"host"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                            </StackLayout>

                                            <legend
                                                style={{ paddingTop: "24px" }}
                                                className={"k-form-legend"}>
                                                One Health Services
                                            </legend>

                                            <div className={"sds-onehealth sds-hstack"}>
                                               <StackLayout gap={48}>
                                                    <span />
                                                    <StackLayout>
                                                        <span>Offered</span>
                                                        <span>Number of Engagements</span>
                                                    </StackLayout>
                                                    <div/>
                                                </StackLayout>

                                                <StackLayout gap={48}>
                                                    <span>Smoking Cessation (NRT - patches, gums, lozenges, sprays)</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B354"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A354"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Dental/Oral care, supplies, consultations, or referrals</span>
                                                    <StackLayout>
                                                       <Field
                                                            name={"B355"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A355"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Harms reduction (naloxone, clean kits, needle exchange, safe inhalation, etc.)</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B356"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A356"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Immunizations - influenza</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B357"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A357"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Immunizations - COVID</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B358"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A358"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Immunizations - TDAP, pneumococcal, MMR or other </span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B359"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A359"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Primary nursing care (wound, blood pressure, consult, referral to community)</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B360"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A360"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Zoonotic infectious disease information / counselling (ticks, lyme dz,rabies)</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B380"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A380"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Personal safety / security</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B381"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A381"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Pharmacist consult</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B378"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A378"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Sexual health/testing </span>
                                                    <StackLayout>
                                                       <Field
                                                            name={"B361"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A361"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Healthy eating</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B362"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A362"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Active living</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B363"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A363"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Social services (social worker consult, housing support, community referral)</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B364"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A364"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Mental health support (counselling, support in vet apppointment, referral)</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B365"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A365"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Hand care</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B366"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A366"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Foot care</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B367"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A367"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Hair cuts</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B368"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A368"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Tax preparation with CRA</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B369"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A369"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Vision support (OneSight): free eyeglasses / vouchers</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B370"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A370"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Ticket defense / legal advice</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B371"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A371"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                                <StackLayout gap={48}>
                                                    <span>Clothing distribution</span>
                                                    <StackLayout>
                                                        <Field
                                                            name={"B383"}
                                                            component={FormCheckbox}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                        <Field
                                                            name={"A383"}
                                                            component={FormInput}
                                                            style={{ borderColor: "black" }}
                                                        />
                                                    </StackLayout>
                                                    <div />
                                                </StackLayout>
                                          </div>

                                            <legend
                                                style={{ paddingTop: "24px", marginBottom:"-24px" }}
                                                className={"k-form-legend"}>
                                                Hours
                                            </legend>
                                            <Field
                                                label={"Number of Volunteer Vet Hours at this event*"}
                                                name={"A349"}
                                                component={FormInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                label={"Number of Volunteer Vet Technician / Vet Assistant / Student Hours at this event*"}
                                                name={"A350"}
                                                component={FormInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                label={"Number of One Health Volunteer Hours at this event (incl. Nurses/nurse practitioners, Pharmacists, Dental Hygienists, Social Workers, Physicians, Health Students, etc.)*"}
                                                name={"A351"}
                                                component={FormInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                label={"Number of Admin Hours for this event (activities before event incl. scheduling appointment times, communicating with referring workers, volunteer coordination, clinic prep/ordering, etc.)*"}
                                                name={"A352"}
                                                component={FormInput}
                                                validator={requiredValidator}
                                            />
                                            <Field
                                                label={"Number of Regional Director Hours for this event*"}
                                                name={"A353"}
                                                component={FormInput}
                                                validator={requiredValidator}
                                            />
                                      </fieldset>
                                    </div>
                                </FormElement>
                            </div>
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
    );
};
export default EventNew;
