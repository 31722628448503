import * as React from "react";
import { Field } from "@progress/kendo-react-form";
import {
    Form,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import {
    Toolbar,
    Button,
} from "@progress/kendo-react-buttons";

import { FormInput } from "./FormComponents";
import { passwordValidator } from "./validators";

import { useAppSelector } from "../store/store";

import { useNewPersonMutation } from '../store/sunbright-api';

export interface PersonPasswordProps {
    role: string;
    personData?: any;
    onReturn: any;
}
const PersonPassword = ({
    role,
    personData,
    onReturn,
}: PersonPasswordProps) => {

    const auth = useAppSelector((state: any) => state.auth);
    const personID = (auth && (auth.user !== undefined) && (auth.user.id !== undefined)) ? auth.user.id : -1;

    const PersonPasswordForm = React.useRef<any>(null);

    const [newPerson, response] = useNewPersonMutation();

    const [errorMessage, setErrorMessage] = React.useState<string>("");

    const submitForm = (event: FormSubmitClickEvent) => {

        const { isValid } = event;

        if (isValid) {
            const postExam = PersonPasswordForm.current.values;

            if (postExam.password != postExam.passwordConfirm) {
                setErrorMessage("Passwords do not match.");
            }
            else {
                postExam.uid = personData ? personData.uid : 0;

                newPerson(postExam)
                    .unwrap()
                    .then((payload) => { })
                    .catch((error) => {
                        console.log(error)
                    });
                onReturn(true);
            }
        }
    }

    return (
        <React.Fragment>
            <div
                style={{ overflow: "auto", marginLeft: "16px" }}
                className={"sds-bold"}
            >
                <Form
                    ref={PersonPasswordForm}
                    onSubmitClick={ submitForm }
                    render={(formRenderProps: FormRenderProps) => (
                        <div>
                            <Toolbar>
                                <Button
                                    icon="upload"
                                    onClick={formRenderProps.onSubmit}
                                >
                                    Submit
                                </Button>
                                <Button
                                    icon="cancel"
                                    onClick={() => (onReturn ? onReturn(false) : null)}
                                >
                                    Cancel
                                </Button>
                            </Toolbar>
                            <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{overflow: "auto", marginLeft: "16px", marginRight: "32px", marginTop:"-32px"}}>
                                    <div className={"sds-questionnaire"}>
                                        <fieldset
                                            className={"k-form-fieldset"}
                                            onFocus={() => { setErrorMessage("") }}
                                        >
                                            {(role !== "Client") &&
                                                <div>
                                                    <Field
                                                        label={"Password*"}
                                                        name={"passwordR"}
                                                        type="password"
                                                        component={FormInput}
                                                        autoComplete={"new-password"}
                                                       validator={passwordValidator}
                                                    />
                                                    <Field
                                                        label={"Confirm Password*"}
                                                        name={"passwordC"}
                                                        type="password"
                                                        component={FormInput}
                                                        autoComplete={"new-password"}
                                                        validator={passwordValidator}
                                                    />
                                                </div>
                                             }
                                        </fieldset>
                                    </div>
                                </FormElement>
                                <p style={{ color: "red", whiteSpace: "pre" }}>{errorMessage + " "}</p>
                                </div>
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
      );
}
export default PersonPassword;