import * as React from "react";

import {
    Grid,
    GridColumn,
    GridSortChangeEvent,
    GridFilterChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState,
} from "@progress/kendo-react-grid";

import {
    orderBy,
    SortDescriptor,
    filterBy,
    CompositeFilterDescriptor,
} from "@progress/kendo-data-query";

import { IntlService } from "@progress/kendo-react-intl";

import { getter } from "@progress/kendo-react-common";

const allLeaflets = [
    {
        "ID": 1,
        "Title": "Behaviour",
        "Description": "Behavior Modification – Working for Food – Dogs and Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "BehaviorWorkingForFood.pdf"
    },
    {
        "ID": 2,
        "Title": "Boosters and Vaccines",
        "Description": "Lifelearn Boosters and Vaccines",
        "Posted": "204-09-05 11:30:00.000",
        "Document": "BoostersAndVaccines.pdf"
    },
    {
        "ID": 3,
        "Title": "Canine - Door Charging",
        "Description": "Dog Behavior Problems – Greeting Behavior, Door Charging",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineDoorCharging.pdf"
    },
    {
        "ID": 4,
        "Title": "Canine- Eye Drops",
        "Description": "Applying Eye Drops to Dogs",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineEyeDrops.pdf"
    },
    {
        "ID": 5,
        "Title": "Canine - Fleas",
        "Description": "Flea Control in Dogs",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineFleas.pdf"
    },
    {
        "ID": 6,
        "Title": "Canine - Food Bowl Aggression",
        "Description": "Aggression in Dogs – Food Bowl",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineFoodBowlAggression.pdf"
    },
    {
        "ID": 7,
        "Title": "Canine - House Soiling",
        "Description": "House Soiling in Dogs",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineHouseSoiling.pdf"
    },
    {
        "ID": 8,
        "Title": "Canine - Spaying",
        "Description": "Spaying in Dogs",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineSpaying.pdf"
    },
    {
        "ID": 9,
        "Title": "Canine - Neutering",
        "Description": "Neutering in Dogs",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineNeutering.pdf"
    },
    {
        "ID": 10,
        "Title": "Canine - Puppy Come Wait Follow",
        "Description": "Puppy – Training Come, Wait and Follow",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CaninePuppyComeWaitFollow.pdf"
    },
    {
        "ID": 11,
        "Title": "Canine - Puppy Sit Down Stay",
        "Description": "Puppy – Training Sit, Down, Stand and Stay",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CaninePuppySitDownStay_1_.pdf"
    },
    {
        "ID": 12,
        "Title": "Canine - Vaccines",
        "Description": "Vaccines For Dogs",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "CanineVaccines.pdf"
    },
    {
        "ID": 13,
        "Title": "Feline - Aggression",
        "Description": "Aggression in Cats – Towards Other Household Cats – Treatment",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineAggressiontocatsRx.pdf"
    },
    {
        "ID": 14,
        "Title": "Feline - Ear Infection",
        "Description": "Ear Infections in Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineEarInfection.pdf"
    },
    {
        "ID": 15,
        "Title": "Feline - Eye Drops",
        "Description": "Applying Eye Drops to Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineEyeDrops.pdf"
    },
    {
        "ID": 16,
        "Title": "Feline - Soiling",
        "Description": "House Soiling in Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineHousesoiling.pdf"
    },
    {
        "ID": 17,
        "Title": "Feline - Kitten Socialization",
        "Description": "Kitten - Socialization and Fear Prevention",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineKittenSocialization.pdf"
    },
    {
        "ID": 18,
        "Title": "Feline - Spaying",
        "Description": "Spaying in Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineSpaying.pdf"
    },
   {
        "ID": 19,
        "Title": "Feline - Neutering",
       "Description": "Neutering in Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineNeutering.pdf"
    },
    {
        "ID": 20,
        "Title": "Feline - Obesity",
        "Description": "Obesity in Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineObesity.pdf"
    },
  {
        "ID": 21,
        "Title": "Feline - Senior Care",
        "Description": "Senior Cat Care – Special Considerations for Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineSeniorCare.pdf"
    },
    {
        "ID": 22,
        "Title": "Feline - Vaccines",
        "Description": "Vaccines For Cats",
        "Posted": "2023-08-23 11:30:00.000",
        "Document": "FelineVaccines.pdf"
    },
]

const initialSort: Array<SortDescriptor> = [];

const initialFilter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [{ field: "PetName", operator: "contains", value: "" }],
};

const DATA_ITEM_KEY = "ID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);


const DATE_FORMAT = "yyyy-MM-dd hh:mm:ss.SSS";
const intl = new IntlService("en");

allLeaflets.forEach((l: any) => {
    l.Posted = intl.parseDate(
        l.Posted ? l.Posted : "",
        DATE_FORMAT
    );
});

const LeafletsGrid = (props: any) => {
    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        setGridHeight((window.innerHeight - 100) + "px");
    }, []);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [sort, setSort] = React.useState(initialSort);
    const [filter, setFilter] = React.useState(initialFilter);
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    const onGridSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let [key, value] = Object.entries(newSelectedState)[0];
        if (value === true) {
            let url = window.location.protocol + "//" + window.location.host + "/leaflets/" + allLeaflets[+key - 1].Document;
            window.open(url);
        }
    }; 

    return (
        <React.Fragment>
           <div className="sds-gridHeader">Leaflets</div>
           <Grid
                style={{ height: gridHeight }}
                className="sds-selectable"
                data={orderBy(filterBy(allLeaflets, filter), sort).map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}
                filterable={true}
                filter={filter}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                resizable={true}
                reorderable={true}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "single",
                }}
                onSelectionChange={onGridSelectionChange}
                size={"small"}
            >
                <GridColumn field="Title" filterable={true} filter="text" width="200px"/>
                <GridColumn field="Description" filterable={true} filter="text" />
                <GridColumn field="Posted" filterable={false} format="{0:yyyy MMM d}" width="100px" />
            </Grid>
        </React.Fragment>
    );
}
export default LeafletsGrid;
