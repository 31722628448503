import { Field } from "@progress/kendo-react-form";

import { TextArea } from "@progress/kendo-react-inputs";
import { StackLayout } from "@progress/kendo-react-layout";

import { FormTextArea, FormDropDownList } from "../FormComponents";
import { requiredValidator } from "../validators";

export interface ExamNewCommentsProps {
    examID: string,
    newExam: boolean,
}
const ExamNewComments = ({
    examID,
    newExam,
}: ExamNewCommentsProps) => {

    return (
        <div className={"sds-questionnaire"} style={{ marginTop: "4px" }}>
            <fieldset
                style={{}}
                className={"k-form-fieldset"}
            >
                <legend
                    style={{ marginBottom: "-16px" }}
                    className={"k-form-legend"}>
                    Add Comment
                </legend>
                {!newExam &&
                    <StackLayout gap={16}>
                        <Field
                            label={"Access"}
                            name={"A310"}
                            component={FormDropDownList}
                            data={["Private", "Public"]}
                        />
                        <div /><div /><div />
                    </StackLayout>
                }
                <Field
                    style={{ height: "120px" }}
                    label={"Comment"}
                    name={"N310"}
                    component={FormTextArea}
                    validator={newExam ? undefined : requiredValidator}
                />
                <p />
                {!newExam &&
                    <Field
                        name={"N310-prev"}
                        style={{ height: "300px" }}
                        readOnly={true}
                        component={TextArea}
                    />
                }
            </fieldset>
            {newExam &&
                <div style={{ fontWeight: "bold", marginTop: "64px", textAlign: "center" }}>
                Click the 'Submit' button (bottom-right) to submit exam for processing.
                </div>
            }
        </div>
    );
}

export default ExamNewComments;
